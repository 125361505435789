<div class="flex gap-2">
  <div
    class="mr-4 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full text-neutral-invert surface-brand-default"
  >
    @if (avatarURL) {
      <img
        class="h-inherit place-items-center rounded-full"
        [src]="avatarURL"
      />
    } @else {
      {{ initials }}
    }
  </div>

  <div class="flex flex-col text-xs text-neutral-disabled">
    <span class="text-base font-medium text-zinc-700">{{
      firstName + ' ' + lastName
    }}</span>
    <span class="mb-1">{{ contactType }}</span>

    @if (shouldShowEnterprise && enterpriseName) {
      <a class="mb-1 flex items-center" (click)="handleClick()">
        <cca-icon
          icon="building"
          class="block w-5 text-brand-default"
        ></cca-icon>
        {{ enterpriseName }}
      </a>
    }

    @if (shouldShowCompany && companyName) {
      <a class="mb-1 flex items-center" (click)="handleClick()">
        <cca-icon icon="folder" class="block w-5 text-brand-default"></cca-icon>
        {{ companyName }}
      </a>
    }

    @if (email) {
      <a
        class="mb-1 flex items-center"
        [attr.href]="email ? 'mailto:' + email : undefined"
        ><cca-icon icon="mail" class="block w-5 text-brand-default"></cca-icon
        >{{ email }}</a
      >
    }
    @if (phone) {
      <a
        class="mb-1 flex items-center"
        [attr.href]="phone ? 'tel:' + phone : undefined"
        ><cca-icon icon="phone" class="block w-5 text-brand-default"></cca-icon
        >{{ phone }}</a
      >
    }
  </div>
</div>
