/**
 * Event API
 * <p>The Event API is used to log and analyze events.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Text  1 = Number  2 = Currency  3 = Date  4 = DateTime  5 = Address  6 = User  7 = UserGroup  8 = Vehicle  9 = NotNull  10 = EstimatedCargoValue  11 = HandleUnitType
 */
export enum AuditValueDisplayType {
  Text = 0,
  Number = 1,
  Currency = 2,
  Date = 3,
  DateTime = 4,
  Address = 5,
  User = 6,
  UserGroup = 7,
  Vehicle = 8,
  NotNull = 9,
  EstimatedCargoValue = 10,
  HandleUnitType = 11,
}
