import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { map } from 'rxjs';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  id,
  ChainCargoAddress,
} from '@cca-infra/common';
import {
  AddressViewModel,
  LocationTypeViewModel,
  TimeZoneViewModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends BaseApiService {
  constructor() {
    super(`location.v1.address`);
  }

  addressPaginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<AddressViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  addressPaginatedFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  addressEnterpriseDelete(addressId: id) {
    return this.http.delete(`${this.url}enterprise/${addressId}`);
  }

  getAddress(locationId: id) {
    return this.http
      .get<{
        address: Partial<ChainCargoAddress>;
        locationName: string;
      }>(`${this.url}${locationId}`)
      .pipe(
        map(
          (addressModel: {
            address: Partial<ChainCargoAddress>;
            locationName: string;
          }) => {
            return {
              ...addressModel.address,
              locationName: addressModel.locationName,
            } as ChainCargoAddress;
          },
        ),
      );
  }

  updateAddress(locationId: id, address: ChainCargoAddress) {
    return this.http.put<ChainCargoAddress>(`${this.url}update`, {
      locationId: locationId,
      updatedAddress: address,
    });
  }

  addAddress(address: ChainCargoAddress) {
    return this.http.post<string>(`${this.url}add`, {
      address: address,
    });
  }

  deleteAddress(locationId: id) {
    return this.http.delete(`${this.url}${locationId}`);
  }

  getAddressTimezone(address: ChainCargoAddress) {
    return this.http.post<TimeZoneViewModel>(`${this.url}timezone`, {
      address: {
        ...address,
        number: address?.number ? String(address.number) : undefined,
      },
    });
  }

  getLocationTypes() {
    return this.http.get<LocationTypeViewModel[]>(
      `${this.url}get-location-types`,
    );
  }
}
