/**
 * Order API
 * <p>The Order API is used to manage Orders within the platform</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = New  1 = SearchForCarrier  2 = CarrierInformed  3 = InTransit  4 = MissingPOD  5 = Completed  6 = Cancelled
 */
export enum ShipperOrderStatus {
  New = 0,
  SearchForCarrier = 1,
  CarrierInformed = 2,
  InTransit = 3,
  MissingPOD = 4,
  Completed = 5,
  Cancelled = 6,
}
