/* 
Conversion table regarding units:
+-------------+---------+-------------+--------+
|   Metric    | symbol  |  Imperial   | symbol |
+-------------+---------+-------------+--------+
| Centimeter  | cm      | Inch        | ln     |
| Meter       | m       | Foot        | ft     |
| CubicMeter  | m³      | CubicFoot   | ft³    |
| SquareMeter | m²      | SquareFoot  | ft²    |
| KiloMeter   | km      | Mile        | mi     |
| Gram        | g       | Ounce       | oz     |
| KiloGram    | kg      | Pound       | lb     |
| MetricTone  | t       | Pound       | lb     |
| Liter       | l       | Gallon      | gal    |
+-------------+---------+-------------+--------+
*/

export enum ImperialUnits {
  Inch = 'inch',
  Foot = 'foot',
  Mile = 'mile',
  Ounce = 'ounce',
  Pound = 'pound',
  Gallon = 'gallon',
  Fahrenheit = 'fahrenheit',
}

export enum MetricUnits {
  Centimeter = 'centimeter',
  Meter = 'meter',
  Kilometer = 'kilometer',
  Gram = 'gram',
  KiloGram = 'kilogram',
  Liter = 'liter',
  Celsius = 'celsius',
}

export type Unit = ImperialUnits | MetricUnits;
