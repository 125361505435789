/**
 *   0 = Undefined  1 = Order  2 = Request  3 = UserGroup  4 = User  5 = Lane  6 = Tender  7 = LaneRequest  8 = CO3Vehicle
 */
export enum EntityType {
  Undefined = 0,
  Order = 1,
  Request = 2,
  UserGroup = 3,
  User = 4,
  Lane = 5,
  Tender = 6,
  LaneRequest = 7,
  CO3Vehicle = 8,
  CarrierOrder = 9,
}
