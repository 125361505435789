import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  Input,
  input,
  OnInit,
  output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { id } from '@cca-infra/common';
import { GroupTreeItemV2 } from '@cca-infra/sequence-management/v1';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { IconComponent } from '../icon';
import { InfoBannerComponent } from '../info-banner';
import { SearchFieldComponent } from '../search-field';
import { SpinnerComponent } from '../spinner';
import { TreeViewComponent, TreeViewNode } from '../tree-view';

// const dataKeys = ['UserGroup'] as const;

@Component({
  selector: 'cca-select-sub-group',
  standalone: true,
  imports: [
    TranslocoModule,
    SearchFieldComponent,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    SpinnerComponent,
    IconComponent,
    MatButtonModule,
    TreeViewComponent,
    InfoBannerComponent,
    NgTemplateOutlet,
    MatTooltipModule,
    MatDividerModule,
  ],
  templateUrl: './select-sub-group.component.html',
  styleUrls: ['./select-sub-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('sequenceStep')],
})
export class SelectSubGroupComponent implements OnInit {
  private cdr = inject(ChangeDetectorRef);

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  dataSource = new MatTableDataSource();
  treeViewData: TreeViewNode[] = [];
  readonly displayedColumns = ['name'];

  @Input() selectedSubGroupId?: string | null;
  subTitleLabel = input.required<string>();
  subTitleTreeviewLabel = input.required<string>();
  searchLabel = input.required<string>();
  subgroupValidationErrorLabel = input.required<string>();
  searchResultsLabel = input.required<string>();
  noDataLabel = input.required<string>();
  tooltipSubgroupHasNoUsersLabel = input.required<string>();
  subGroups = input<GroupTreeItemV2[]>([]);
  showValidationError = input<boolean>(false);
  displayInTree = input<boolean>(true);
  showTitle = input<boolean>(true);
  groupSelected = output<string | null>();

  isTreeView = true;
  flatTree: GroupTreeItemV2[] = [];
  prevIsTreeView = false;
  searching = false;
  pageIndex = 0;
  searchText = '';
  enterpriseName?: string | null;

  constructor() {
    effect(() => {
      const treeData = this.subGroups();
      this._initDataSource(treeData);
      this.cdr.markForCheck();
    });

    effect(() => {
      const displayInTree = this.displayInTree();
      this.isTreeView = displayInTree;
    });
  }

  ngOnInit(): void {
    this._initDataSource(this.subGroups());
  }

  convertGroupTreeToTreeData(
    groupTree?: GroupTreeItemV2[] | null,
  ): TreeViewNode[] | undefined {
    return groupTree?.map((group) => {
      const node: TreeViewNode = {
        id: group.groupId,
        name: group.groupName,
        isSelectable: group.isSelectable,
        parentId: group.parentId,
        anyUsersInGroup: group.anyUsersInGroup,
        children: this.convertGroupTreeToTreeData(group.children),
      };

      return node;
    });
  }

  radioChanged(event: MatRadioChange) {
    if (event && event.source.checked && event.value) {
      this.setSelectedSubGroupId(event.value);
    }
  }

  setSelectedSubGroupId(subGroupId: id | null) {
    this.selectedSubGroupId = subGroupId ?? null;
    this.groupSelected.emit(subGroupId);
  }

  // get a list of groups from the tree view
  flattenTree(tree: GroupTreeItemV2[]): GroupTreeItemV2[] {
    let flattenedTree: GroupTreeItemV2[] = [];
    tree.forEach((node) => {
      if (node.isSelectable) {
        flattenedTree.push(node); // Add the current node
      }
      if (node.children && node.children.length > 0) {
        // Recursively add children
        flattenedTree = flattenedTree.concat(this.flattenTree(node.children));
      }
    });
    return flattenedTree;
  }

  onSearchChange(search: string | undefined) {
    this.searchText = (search ?? '').trim().toLowerCase();
    // if has text in searchfield show list view
    if (this.searchText) {
      this.isTreeView = false;
      this.searching = true;
      // else switch back to previous view
    } else {
      this.searching = false;
      this.isTreeView = this.prevIsTreeView;
    }
    this.dataSource.filter = this.searchText;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // set the right page of the selected group
  setSelectedGroupPage() {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.flatTree[i].groupId === this.selectedSubGroupId) {
        this.pageIndex = Math.floor(i / 10);
      }
    }
  }

  // switch the view
  switchView() {
    this.setSelectedGroupPage();
    this.isTreeView = !this.isTreeView;
    this.prevIsTreeView = this.isTreeView;
  }

  private _initDataSource(treeData: GroupTreeItemV2[]) {
    if (treeData[0]) {
      this.flatTree = this.flattenTree(treeData);
      this.dataSource.data = this.flatTree;
      this.dataSource.filterPredicate = (group: unknown, filter) => {
        return (
          (group as GroupTreeItemV2).groupName
            ?.toLowerCase()
            ?.includes(filter.toLowerCase()) ?? false
        );
      };
      this.treeViewData = this.convertGroupTreeToTreeData(treeData) ?? [];
      this.enterpriseName = treeData[0].groupName;
    }
  }
}
