/**
 * Order API
 * <p>The Order API is used to manage Orders within the platform</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Empty  1 = Full
 */
export enum ContainerStatus {
  Empty = 0,
  Full = 1,
}
