import { DateTime } from 'luxon';

export const coeff = 1000 * 60 * 5;

function ceilToNearest5Minutes(date: DateTime) {
  const millis = date.toMillis();
  const roundedMillis = Math.ceil(millis / coeff) * coeff;
  return DateTime.fromMillis(roundedMillis);
}

// Function to calculate milliseconds until the next rounded 5 minutes
export function millisTillNextRound5Minutes() {
  const now = DateTime.now();
  return ceilToNearest5Minutes(now).toMillis() - now.toMillis();
}
