<div class="page-container flex flex-col gap-4 text-neutral-body">
  <h2 class="text-xl">{{ title }}</h2>
  <div class="flex items-center gap-4">
    <div
      class="grid h-20 w-20 flex-shrink-0 place-items-center overflow-hidden rounded-full text-3xl font-medium info-surface-light info-text-dark"
    >
      @if (avatarURL) {
        <img [src]="avatarURL" />
      } @else {
        {{ initials }}
      }
    </div>
    <div class="flex flex-col">
      <h3 class="text-lg">
        {{ firstName + ' ' + lastName }}
      </h3>
      @if (contactType) {
        <p class="text-sm text-neutral-subtitle">
          {{ contactType }}
        </p>
      }
      @if (companyName && flagUrl) {
        <div class="ml-[1px] mt-2 flex items-center gap-2">
          <cca-country-flag
            class="h-3 w-3 rounded-sm"
            [url]="flagUrl"
          ></cca-country-flag>
          <h1 class="text-xs font-normal text-neutral-body">
            {{ companyName }}
          </h1>
        </div>
      }
      @if (phone) {
        <div class="flex items-center gap-2">
          <cca-icon
            icon="phone"
            class="text-sm text-neutral-caption"
          ></cca-icon>
          <h1 class="text-xs font-normal text-neutral-body">
            {{ phone }}
          </h1>
        </div>
      }
      @if (email) {
        <div class="flex items-center gap-2">
          <cca-icon icon="mail" class="text-sm text-neutral-caption"></cca-icon>
          <a
            href="mailto:{{ email }}"
            class="text-sm font-normal underline underline-offset-[6px] text-brand-default"
            >{{ email }}</a
          >
        </div>
      }
    </div>
  </div>
</div>
