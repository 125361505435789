import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  Injectable,
  InjectionToken,
  Injector,
  StaticProvider,
  inject,
  signal,
} from '@angular/core';
import { Subscription } from 'rxjs';

export type OverlayCloseType = 'backdropClick' | 'reset' | 'submit';

export interface OverlayCloseEvent<R> {
  type: OverlayCloseType;
  data?: R;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CUSTOM_OVERLAY_DATA = new InjectionToken<{ data?: any }>(
  'CUSTOM_OVERLAY_DATA',
);

@Injectable({
  providedIn: 'root',
})
export class OverlayService<T = unknown, R = T> {
  private overlay = inject(Overlay);
  private injector = inject(Injector);

  private overlayRef: OverlayRef | null = null;
  private backDropClickSubscription: Subscription | null = null;

  afterClosed = signal<OverlayCloseEvent<R> | null>(null);

  openCustomOverlay<C>(
    attachTo: HTMLElement,
    customOverlayComponent: ComponentType<C>,
    data?: T,
    position?: {
      originX?: 'start' | 'center' | 'end';
      originY?: 'center' | 'bottom' | 'top';
      overlayX?: 'start' | 'center' | 'end';
      overlayY?: 'center' | 'bottom' | 'top';
      offsetX?: number;
      offsetY?: number;
    },
  ): void {
    // Close the previous overlay if it's still open
    if (this.overlayRef?.hasAttached()) {
      this._close('backdropClick');
    }

    const {
      originX = 'start',
      originY = 'bottom',
      overlayX = 'start',
      overlayY = 'top',
      offsetX = 0,
      offsetY = 0,
    } = position || {};

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(attachTo)
      .withPositions([
        {
          originX,
          originY,
          overlayX,
          overlayY,
          offsetX,
          offsetY,
        },
      ]);

    this.overlayRef = this.overlay.create({
      disposeOnNavigation: true,
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });

    const injector = this._createInjector(data);

    const customOverlayPortal = new ComponentPortal(
      customOverlayComponent,
      null,
      injector,
    );

    this.overlayRef.attach(customOverlayPortal);

    this.backDropClickSubscription = this.overlayRef
      .backdropClick()
      .subscribe(() => {
        this._close('backdropClick');
      });
  }

  close(type: OverlayCloseType, data?: R) {
    this._close(type, data);
  }

  private _close(type: OverlayCloseType, data?: R) {
    if (this.overlayRef) {
      if (this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      }
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    this.backDropClickSubscription?.unsubscribe();
    this.backDropClickSubscription = null;

    this.afterClosed.set({
      type,
      data,
    });
  }

  private _createInjector(data?: T): Injector {
    const providers: StaticProvider[] = [
      {
        provide: CUSTOM_OVERLAY_DATA,
        useValue: { data },
      },
    ];

    return Injector.create({
      parent: this.injector,
      providers,
    });
  }
}
