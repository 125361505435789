import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {} from '@cca-infra/common';
import {
  GetCarrierEnterprisesForLaneTenderRequestV2,
  GetCarrierGroupsForLaneTenderRequestV2,
  TenderCarrierGroupViewModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class LaneTenderCarriersService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getCarrierEnterpriseGroupsForLaneTender(
    request: GetCarrierEnterprisesForLaneTenderRequestV2,
  ) {
    return this.http.post<TenderCarrierGroupViewModel[]>(
      `${this.url}get-carrier-enterprises-for-lane-tender`,
      request,
    );
  }

  getCarrierGroupsForLaneTender(
    request: GetCarrierGroupsForLaneTenderRequestV2,
  ) {
    return this.http.post<TenderCarrierGroupViewModel[]>(
      `${this.url}get-carrier-groups-for-lane-tender`,
      request,
    );
  }
}
