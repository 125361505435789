<div
  class="-ml-3 box-content grid flex-shrink-0 place-items-center rounded-full border-2 info-surface-light info-text-dark"
  [class]="'h-' + size + ' w-' + size"
  [ngClass]="{
    'border-white': mode === Mode.Light,
    'border-neutral-dark-700': mode === Mode.Dark,
  }"
>
  @if (url) {
    <img
      class="rounded-full"
      [class]="'h-' + size + ' w-' + size"
      [src]="url"
    />
  } @else {
    <p class="text-xs font-medium">{{ initials ?? '' }}</p>
  }
</div>
