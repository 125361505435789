import { BaseApiService } from '@cca-infra/core';
import {
  GroupBasicInfoViewModel,
  UserGroupViewModel,
  CreateProspectUserGroupV2Request,
  V2EnterpriseGroupsViewModel,
} from '../model';
import { Injectable } from '@angular/core';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  id,
} from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class UserGroupServiceV2 extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getBasicInfo(groupId: id) {
    return this.http.get<GroupBasicInfoViewModel>(
      `${this.url}detail/basic-info/${groupId}`,
    );
  }

  getCarrierEnterpriseManagedTrans(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<V2EnterpriseGroupsViewModel>>(
      `${this.url}get-carrier-enterprises-for-managed-transport-tender`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  getCarrierGroupsManagedTransEnterprise(enterpriseGroupId: id) {
    return this.http.post<V2EnterpriseGroupsViewModel[]>(
      `${this.url}get-carrier-groups-for-managed-transport-tender`,
      { enterpriseGroupId: enterpriseGroupId, searchText: '' },
    );
  }

  createProspect(
    createProspectUserGroupRequest: CreateProspectUserGroupV2Request,
  ) {
    return this.http.post<UserGroupViewModel>(
      `${this.url}create-prospect`,
      createProspectUserGroupRequest,
    );
  }

  deleteGroup(groupId: id) {
    return this.http.delete(`${this.url}delete/${groupId}`, {
      responseType: 'text',
    });
  }
}
