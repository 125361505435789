import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IconComponent } from '../icon';
import { CountryFlagComponent } from '../country-flag';

@Component({
  selector: 'cca-contact-card',
  standalone: true,
  imports: [IconComponent, CountryFlagComponent],
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCardComponent implements OnChanges {
  @Input() title: string | null | undefined;
  @Input() name: string | null | undefined;
  @Input() firstName: string | undefined;
  @Input() lastName: string | undefined;
  @Input() email: string | null | undefined = '';
  @Input() phone: string | null | undefined = '';
  @Input() contactType: string | undefined = undefined;
  @Input() companyName?: string | null;
  @Input() flagUrl?: string | null;
  @Input() avatarURL: string | null | undefined = '';

  initials = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.createInitialsOrAvatar();
    }
  }

  createInitialsOrAvatar() {
    if (!this.firstName || !this.lastName) {
      if (this.name) {
        //some good ol' fashioned array manipulation
        const splitName = this.name.split(' ');
        this.firstName = splitName[0];
        this.lastName = splitName.slice(1).join(' ');
        this.initials = `${this.firstName?.charAt(0) ?? '?'}${
          this.lastName?.charAt(0) ?? '?'
        }`.toUpperCase();
      }
    } else {
      this.initials = `${this.firstName?.charAt(0) ?? '?'}${
        this.lastName?.charAt(0) ?? '?'
      }`.toUpperCase();
    }
  }
}
