import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoModule } from '@jsverse/transloco';
import { IconComponent } from '../icon';

interface GeneralBookingDialogData {
  description: string;
  label: string;
  cancelButton: string;
  hasTwoButtons: boolean;
}
@Component({
  selector: 'cca-general-booking-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    IconComponent,
    MatFormFieldModule,
    MatDialogModule,
    TranslocoModule,
  ],
  templateUrl: './general-booking-dialog.component.html',
  styleUrls: ['./general-booking-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralBookingDialogComponent implements OnInit {
  private _data: GeneralBookingDialogData | undefined;

  constructor() {
    this._data = inject(MAT_DIALOG_DATA);
  }

  @Input() label: string | undefined;
  @Input() description: string | undefined;
  @Input() confirmButton: string | undefined;
  @Input() cancelButton: string | undefined;
  @Input() hasTwoButtons = true;

  @Output() confirmButtonClick = new EventEmitter();
  @Output() cancelButtonClick = new EventEmitter();

  ngOnInit() {
    if (this._data) {
      const { description, label, cancelButton, hasTwoButtons } = this._data;
      this.description = description;
      this.label = label;
      this.cancelButton = cancelButton;
      this.hasTwoButtons = hasTwoButtons ?? true;
    }
  }
}
