import { Injectable } from '@angular/core';
import {
  AdaptPaginationRequest,
  AuditLogItemViewModel,
  DomainType,
  Filter,
  id,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
} from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import { map, Observable } from 'rxjs';
import {
  AddressViewModel,
  AreaOfOperation,
  FilterChoiceOption,
  GetEnterprisesByGroupTypesRequest,
  GetSelectableSubGroupsTreeViewByFeatureRequest,
  GetSubgroupsByFeatureAndPermissionsRequest,
  GetUserGroupRequest,
  GroupCarrierLaneItemViewModel,
  GroupLicenseViewModel,
  GroupPaginationInvoicingItemViewModel,
  GroupPaginationItemViewModel,
  GroupTreeItemV2,
  GroupTreeViewModel,
  MainVerticalViewModel,
  MTDefaultCurrencyViewModel,
  PermissionType,
  SalesOrganisationGroupViewModel,
  SetMTDefaultCurrencyRequest,
  SetUserGroupFeatureRequest,
  SimpleEnterpriseGroupsViewModel,
  SupportedCurrenciesViewModel,
  UserDetailViewModel,
  UserGroupFeaturesViewModel,
  UserGroupSimpleViewModel,
  UserGroupViewModel,
  UserManagementFileType,
} from '../model';
import { PermissionKey } from '../permission-key';

type UserEnterpriseGroupQuery = {
  groupTypeId?: DomainType;
  permissionKeys?: PermissionKey[] | null;
  permissionType?: PermissionType | null;
};

type UserGroupQuery = {
  enterpriseGroupId?: id;
  userGroupId?: id | null;
  permissionKeys?: PermissionKey[] | null;
  permissionType?: PermissionType | null;
  groupTypeId?: DomainType | null;
};

export type GetCO2OffsetRequest = {
  startDateTime: number;
  offsetPercentage: number;
  isActive: boolean;
};

export interface UserGroupTree {
  enterpriseGroupId: id;
  enterpriseGroupName: string;
  topLevelItem: UserGroupTreeNode;
  topLevelHasChildren: boolean;
  children?: UserGroupTreeNode[];
}

export interface UserGroupTreeNode {
  enterpriseGroupName: string;
  enterpriseGroupId: id;
  enterpriseHasChildren: boolean;
  groupId: id;
  groupName?: string | null | undefined;
  parentId: id | undefined;
  currentUserIsInGroup: boolean;
  anyUserIsInGroup: boolean;
  parent?: UserGroupTreeNode;
  children?: UserGroupTreeNode[];
  isSelectable: boolean;
  domainGroupTypeIds: DomainType[];
  defaultComment: string;
  billToGroupId?: null | id;
  billToName?: null | id;
  billFromGroupId?: null | id;
  billFromName?: null | id;
}

@Injectable({
  providedIn: 'root',
})
export class UserGroupService extends BaseApiService {
  constructor() {
    super(`user.v1.usergroup`);
  }

  allPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<GroupPaginationItemViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  enterpriseByFeaturePagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(`${this.url}paginated-enterprises-by-feature`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  userGroupPaginationCount(filters: Filter[]): Observable<number> {
    return this.http.post<number>(`${this.url}paginated-count`, {
      filterDefinitions: filters,
    });
  }

  userGroupExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}paginated-export`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
        observe: 'response',
      },
    );
  }

  allPaginationInvoice(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ): Observable<PaginationResponse<GroupPaginationInvoicingItemViewModel>> {
    return this.http.post<
      PaginationResponse<GroupPaginationInvoicingItemViewModel>
    >(`${this.url}paginated-for-invoicing`, {
      ...AdaptPaginationRequest(paginationRequest),
      ...extraParams,
    });
  }

  allPaginationFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  carrierLanePagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ): Observable<PaginationResponse<GroupCarrierLaneItemViewModel>> {
    return this.http.post<PaginationResponse<GroupCarrierLaneItemViewModel>>(
      `${this.url}paginated-for-carrierlane`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  carrierLanePaginationFilters() {
    return this.http.get(`${this.url}paginated-for-carrierlane-filters`);
  }

  getBrandingImage() {
    return this.http.get<string>(`${this.url}get-branding-image`);
  }

  getAreaOfOperations() {
    return this.http.get<AreaOfOperation[]>(
      `${this.url}get-area-of-operation-options`,
    );
  }

  getUserGroupFeatures(userGroupId: id) {
    return this.http.get<UserGroupFeaturesViewModel[]>(
      `${this.url}detail/features/${userGroupId}`,
    );
  }

  getMTDefaultCurrency(userGroupId: id) {
    return this.http.get<MTDefaultCurrencyViewModel>(
      `${this.url}feature/get-mt-default-currency/${userGroupId}`,
    );
  }

  getSupportedCurrencies() {
    return this.http.get<SupportedCurrenciesViewModel>(
      `${this.url}get-supported-currencies`,
    );
  }

  setMTDefaultCurrency(selectedCurrency: SetMTDefaultCurrencyRequest) {
    return this.http.post(
      `${this.url}feature/set-mt-default-currency`,
      selectedCurrency,
    );
  }

  setUserGroupFeatures(features: SetUserGroupFeatureRequest) {
    return this.http.post(`${this.url}set-features`, features);
  }

  uploadGroupAvatar(userGroupId: id, avatarFile: File) {
    const formData: FormData = new FormData();
    formData.set('FormFile', avatarFile);
    formData.set('UserGroupId', userGroupId);
    formData.set(
      'UserGroupFileType',
      String(UserManagementFileType.AvatarPicture),
    );

    return this.http.post(`${this.url}upload`, formData);
  }

  groupExists(groupName: string) {
    return this.http.post(`${this.url}exists`, { groupName: groupName });
  }

  getEnterpriseUserGroupsByType(request: GetEnterprisesByGroupTypesRequest) {
    return this.http.post<UserGroupViewModel[]>(
      `${this.url}get-enterprises-by-grouptypes`,
      request,
    );
  }

  getUserGroupTreeViewEnterprise(treeQuery: UserEnterpriseGroupQuery) {
    return this.http
      .post<
        GroupTreeViewModel[]
      >(`${this.url}treeview-toplevel-by-grouptype`, treeQuery)
      .pipe(
        map((res) => {
          return res.map((item) => {
            return {
              enterpriseGroupId: item.enterpriseGroupId,
              enterpriseGroupName: item.enterpriseGroupName,
              groupId: item.topLevelItem.groupId,
              groupName: item.topLevelItem.groupName,
              isSelectable: item.topLevelItem.isSelectable,
              enterpriseHasChildren: item.topLevelHasChildren,
              currentUserIsInGroup:
                item?.topLevelItem?.currentUserIsInGroup ?? false,
              anyUserIsInGroup: item?.topLevelItem?.anyUserIsInGroup ?? false,
              parentId:
                item.topLevelItem.parentId !==
                '00000000-0000-0000-0000-000000000000'
                  ? item.topLevelItem.parentId
                  : undefined,
              domainGroupTypeIds: item.topLevelItem.domainGroupTypeIds,
              defaultComment: item.topLevelItem.defaultComment,
            } as UserGroupTreeNode;
          });
        }),
      );
  }

  getUserGroupTreeViewTopLevel(groupTypeId?: DomainType) {
    return this.http.get<GroupTreeViewModel[]>(
      `${this.url}treeview-toplevel/${groupTypeId}`,
    );
  }

  getUserGroupTreeViewWithParent(treeQuery: UserGroupQuery) {
    return this.http
      .post<UserGroupTree>(`${this.url}treeview`, treeQuery)
      .pipe(map((res) => res.topLevelItem));
  }

  getUserGroupTreeView(
    treeQuery: UserGroupQuery,
  ): Observable<UserGroupTreeNode[]> {
    return this.getUserGroupTreeViewWithParent(treeQuery).pipe(
      map((res) => res?.children ?? []),
    );
  }

  getUserGroupData(request: GetUserGroupRequest) {
    return this.http.post<UserGroupViewModel>(
      `${this.url}get-usergroup-details`,
      request,
    );
  }

  getVerticals() {
    return this.http.get<MainVerticalViewModel[]>(`${this.url}verticals`);
  }

  getLicenseTypes() {
    return this.http.get<GroupLicenseViewModel[]>(`${this.url}license-types`);
  }

  getManagers(userGroupId: string) {
    return this.http.get<UserDetailViewModel[]>(
      `${this.url}get-managers/${userGroupId}`,
    );
  }

  getGroupTreeviewFeature(
    body: GetSelectableSubGroupsTreeViewByFeatureRequest,
  ) {
    return this.http.post<GroupTreeItemV2[]>(
      `${this.url}group-treeview-by-feature`,
      body,
    );
  }

  addVertical(mainId: id, name: string) {
    return this.http.post(`${this.url}verticals/create`, {
      verticalMainCategoryId: mainId,
      verticalSubCategoryName: name,
    });
  }

  deleteVertical(subId: id) {
    return this.http.delete(`${this.url}verticals/${subId}`);
  }

  connectToExact(
    userGroupId: id,
    exactGroupId: id,
    isCarrierConnection: boolean,
  ) {
    return this.http.post(`${this.url}connect-to-exact`, {
      userGroupId,
      exactGroupId,
      IsCarrierConnection: isCarrierConnection,
    });
  }

  getCO2Offset(userGroupId: id): Observable<GetCO2OffsetRequest> {
    return this.http.get<GetCO2OffsetRequest>(
      `${this.url}get-co2-offset/${userGroupId}`,
    );
  }

  getCcaSalesOrganizations(): Observable<SalesOrganisationGroupViewModel[]> {
    return this.http.get<SalesOrganisationGroupViewModel[]>(
      `${this.url}get-sales-organisations`,
    );
  }

  getSalesOrganizationsBasedOnEnterprise(
    shipperEnterpriseId?: id,
  ): Observable<SalesOrganisationGroupViewModel[]> {
    return this.http.get<SalesOrganisationGroupViewModel[]>(
      `${this.url}get-sales-organisations/${shipperEnterpriseId}`,
    );
  }

  getEnterpriseMTCarriersForShipper(
    shipperGroupId: id,
  ): Observable<UserGroupSimpleViewModel[]> {
    return this.http.post<UserGroupSimpleViewModel[]>(
      `${this.url}get-mt-carriers-for-shipper`,
      {
        shipperGroupId,
      },
    );
  }

  getAddress(usergroupId: id) {
    return this.http.get<AddressViewModel>(`${this.url}address/${usergroupId}`);
  }

  getEventLog(groupId: id) {
    return this.http.get<AuditLogItemViewModel[]>(
      `${this.url}eventlog/${groupId}`,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }

  getSubgroupsByFeature(request: GetSubgroupsByFeatureAndPermissionsRequest) {
    return this.http.post<SimpleEnterpriseGroupsViewModel[]>(
      `${this.url}get-subgroups-by-feature-permission`,
      request,
    );
  }
}
