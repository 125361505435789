import { Injectable, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  Observable,
  switchMap,
  of,
  map,
  debounceTime,
  distinctUntilChanged,
  take,
} from 'rxjs';
import { UserService } from '@cca-infra/user-management/v1';

@Injectable({
  providedIn: 'root',
})
export class UserNameUniqueValidator {
  private userService = inject(UserService);

  userNameUniqueValidator(
    exception?: string | null | undefined,
    userGroupId?: string | string | null,
  ) {
    return (
      control: AbstractControl,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<{ [key: string]: any } | null> => {
      const value = control.value as string;
      if (value === null || value?.length === 0) {
        return of(null);
      } else if (exception && exception === value) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(400),
          distinctUntilChanged(),
          switchMap(() => {
            return this.userService.userExists(
              control.value,
              userGroupId ?? undefined,
            );
          }),
          map((res) => {
            if (res) {
              return { userNameAlreadyExists: true };
            } else {
              return null;
            }
          }),
          take(1),
        );
      }
    };
  }
}
