import { Injectable, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UserGroupService } from '@cca-infra/user-management/v1';
import {
  Observable,
  of,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
  take,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserGroupNameUniqueValidator {
  private groupService = inject(UserGroupService);

  UserGroupNameUniqueValidator(exception?: string | null | undefined) {
    exception = String(exception).trim();
    return (
      control: AbstractControl,
    ): Observable<{ [key: string]: unknown } | null> => {
      const value = String(control.value).trim();
      // no value
      if (value === null || value?.length === 0) {
        return of(null);
      }
      // exception
      else if (exception && exception === value) {
        return of(null);
      }
      // check with backend
      else {
        return control.valueChanges.pipe(
          debounceTime(400),
          distinctUntilChanged(),
          switchMap(() => {
            return this.groupService.groupExists(value);
          }),
          map((res) => {
            if (res) {
              return { groupNameAlreadyExists: true };
            } else {
              return null;
            }
          }),
          take(1),
        );
      }
    };
  }
}
