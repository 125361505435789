/**
 * Trip API
 * <p>The Trip API is used to manage trips in combination with orders</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = UnAssigned  1 = Planned  2 = InTransit  3 = MissingPOD  4 = Completed  5 = Cancelled
 */
export enum CarrierOrderStatus {
  UnAssigned = 0,
  Planned = 1,
  InTransit = 2,
  MissingPOD = 3,
  Completed = 4,
  Cancelled = 5,
}
