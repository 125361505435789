/// <reference types="@types/google.maps" />
import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  map,
  retry,
  share,
  observeOn,
  asyncScheduler,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoader {
  private document = inject<Document>(DOCUMENT);

  private _observableInstance$: Observable<boolean>;

  /**
   * Sets up our internal (hot) observable
   */
  constructor() {
    const document = this.document;

    this._observableInstance$ = new Observable<boolean>((observer) => {
      // create a script
      const script = document.createElement('script');
      script.id = 'googleMapsSDK';

      /**
       * We need to use the beta version (for now?), since the language property is only available in the beta version ( even though it is listed on the api documentation which reflects the weekly channel? )
       *
       * callback is required with latest version however we don't need this ourself so we provide a NOOP function that does nothing
       */
      script.src =
        'https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyBSHO2Y9rFExINzSCqTd3CSXw7GC9cHE9o&libraries=places,drawing&language=en&callback=Function.prototype';
      script.async = true;
      script.defer = true;

      // listener for when script does load
      const onLoadListener = () => {
        observer.next(true);
        observer.complete();
        script.removeEventListener('load', onLoadListener);
        script.removeEventListener('error', onErrorListener);
      };

      // listener for when script fails to load
      const onErrorListener = () => {
        observer.error(
          new Error(
            `GoogleMapsLoader: Failed to load google map from url: ${script.src}`,
          ),
        );
        script.removeEventListener('load', onLoadListener);
        script.removeEventListener('error', onErrorListener);

        // remove the script if it failed to load
        script.remove();
      };

      // add listeners
      script.addEventListener('load', onLoadListener);
      script.addEventListener('error', onErrorListener);

      // add script to head
      const head = this.document.getElementsByTagName('head')[0];
      if (head) {
        head.appendChild(script);
      }
    }).pipe(
      // automatically retry up to 3 times
      retry(3),

      // share this observable, only reset when a error happens
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: true,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * Calling this and subscribing to it will cause the googleMapsSDK to be loaded
   * multiple calls to this will result in the same value (true) and after it has loaded once it will return same value to every observer
   * If it failed to load it will emit a error (false), and we can try again
   */
  load() {
    return this._observableInstance$;
  }

  /**
   * Will call load internally, and if loaded return a new instance of google maps directions service
   * shares the google maps directions service
   * @returns
   */
  directionService$() {
    return this.load().pipe(
      observeOn(asyncScheduler),
      map(() => new google.maps.DirectionsService()),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: true,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * Will call load internally, and if loaded return a new instance of google maps directions renderer
   * shares the google maps directions renderer
   * @returns
   */
  directionsRenderer$() {
    return this.load().pipe(
      map(() => {
        return new google.maps.DirectionsRenderer({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: '#53389E',
            strokeWeight: 3,
          },
        });
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnComplete: false,
        resetOnError: true,
        resetOnRefCountZero: false,
      }),
    );
  }
}
