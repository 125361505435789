import { inject } from '@angular/core';
import {
  Language,
  TranslationService,
} from '@cca-infra/translation-management/v1';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { exhaustMap, pipe } from 'rxjs';
import { withDevTracking } from './util';

type LanguageOptionState = {
  availableLanguages: Language[];
};

export const LanguageOptionStore = signalStore(
  {
    providedIn: 'root',
  },
  withState<LanguageOptionState>({
    availableLanguages: [],
  }),
  withMethods((store) => {
    const translationService = inject(TranslationService);
    return {
      loadLanguageOptions: rxMethod<void>(
        pipe(
          exhaustMap(() =>
            translationService.getLanguages().pipe(
              tapResponse(
                (languages) => {
                  patchState(store, () => ({
                    availableLanguages: languages,
                  }));
                },
                (err) => {
                  console.error(`failed to load languages`, err);
                },
              ),
            ),
          ),
        ),
      ),
    };
  }),
  withHooks({
    onInit(store) {
      store.loadLanguageOptions();
    },
  }),
  withDevTracking('LanguageOptionStore'),
);
