import {
  Directive,
  EmbeddedViewRef,
  isDevMode,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';

@Directive({
  selector: '[ccaHideInProduction]',
  standalone: true,
})
export class CdkHideInProductionDirective implements OnDestroy {
  private _viewContainer = inject(ViewContainerRef);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private templateRef = inject<TemplateRef<any>>(TemplateRef);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  embeddedView: EmbeddedViewRef<any> | undefined;

  constructor() {
    if (isDevMode()) {
      // save the embedded view reference
      this.embeddedView = this._viewContainer.createEmbeddedView(
        this.templateRef,
      );
    }
  }

  ngOnDestroy(): void {
    // if reference exist then destroy it
    if (this.embeddedView) {
      this.embeddedView.destroy();
    }
  }
}
