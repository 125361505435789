import { NgClass, DecimalPipe } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  inject,
  input,
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { LetDirective, PushPipe } from '@ngrx/component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';
import { CdkTextLimiterPipe } from '@cca-common/cdk';
import { Snackbar } from '@cca-common/snackbar';
import { OrderFileViewModel } from '@cca-infra/order-management/v1';
import { IconComponent } from '../../icon';
import { SpinnerComponent } from '../../spinner';

interface translations {
  chooseFileOrDragChooseText: string;
  chooseFileOrDragYourFilesText: string;
  chooseFileOrDragBrowseText: string;
  successfullyUploaded: string;
  wrongFormat: string;
  fileToBig: string;
  noFileSelected: string;
}

const dragNDrop = [
  trigger('DragNDrop', [
    state('*', style({ transform: 'rotate(0)' })),
    state('hover', style({ transform: 'rotate(-20deg)' })),
    transition('* => hover', animate('300ms')),
    transition('hover => *', animate('300ms')),
  ]),
];

export const defaultAllowedFileTypes = [
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'doc',
  'docx',
  'pdf',
  'png',
  'xlsx',
  'xls',
  'ppt',
  'pptx',
  'txt',
  'mp4',
  'mov',
  'wmv',
  'mkv',
  'webm',
  'flv',
  'svg',
  'avi',
  'pages',
  'heic',
  'heif',
  'webp',
];

export const defaultSelectableFileTypes =
  'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf, text/plain, application/pdf, .pages, video/*';

@Component({
  standalone: true,
  imports: [
    NgClass,
    IconComponent,
    SpinnerComponent,
    TranslocoModule,
    MatProgressBarModule,
    MatCardModule,
    PushPipe,
    LetDirective,
    DecimalPipe,
    CdkTextLimiterPipe,
  ],
  selector: 'cca-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('fileUpload')],
  animations: [dragNDrop],
})
export class FileUploadComponent {
  @Input() maxFileSize = 1024 * 1024 * 20;
  @Input() verticalLayout = false;
  //Usually document store inputs
  @Input() documents: OrderFileViewModel[] | undefined;
  @Input() files: File[] | undefined;
  @Input() isUploading = false;
  @Input() uploadProgress = 0;
  @Input() uploadSuccess = false;
  @Input() translations: translations | undefined;
  @Input() allowedFileTypes?: string[] = defaultAllowedFileTypes;
  selectableFileTypes = input<string>(defaultSelectableFileTypes);

  @Output() fileUpload = new EventEmitter<File>();
  @Output() removeFile = new EventEmitter<File>();

  private snackbar = inject(Snackbar);

  currentlyUploadingFile: File | undefined;
  dragging = false;
  fileToBig = false;
  lastTarget: HTMLInputElement | undefined;

  acceptedFileFormat = true;

  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragging = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @HostListener('dragleave', ['$event']) onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragging = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @HostListener('drop', ['$event']) ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragging = false;

    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      const file = (files as FileList)[0];
      this.changeFile(file);
    }
  }

  openFileDialog() {
    this?.fileInput?.nativeElement?.click();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputFileChanged($event: any) {
    if (
      $event.target &&
      $event.target.files &&
      $event.target.files.length > 0
    ) {
      this.lastTarget = $event.target;
      const file = ($event.target.files as FileList)[0];
      this.currentlyUploadingFile = file;
      this.changeFile(file);
    }
  }

  changeFile(file: File) {
    this.checkForFileFormat(file);
    this.fileToBig = false;
    if (file.size >= this.maxFileSize) {
      this.fileToBig = true;
      this.snackbar.openError(t('fileUpload.snackbar.bigFile'));
    }
    if (!this.acceptedFileFormat) {
      this.snackbar.openError(t('fileUpload.snackbar.wrong Format'));
    }
    if (!this.fileToBig && this.acceptedFileFormat) {
      this.fileUpload.emit(file);
    }
  }

  removeAFile(file: File) {
    this.removeFile.emit(file);
    if (this.lastTarget) {
      this.lastTarget.value = '';
    }
  }

  checkForFileFormat(file: File) {
    const extension = file?.name?.split('.').pop();
    if (this.allowedFileTypes?.includes(extension?.toLowerCase() as string)) {
      this.acceptedFileFormat = true;
    } else {
      this.acceptedFileFormat = false;
    }
  }
}
