/* eslint-disable @typescript-eslint/no-explicit-any */
import { OnDestroy, Pipe, PipeTransform, inject } from '@angular/core';
import { BaseLocalePipe } from '@jsverse/transloco-locale';
import { Subscription } from 'rxjs';
import { NumberPipeFormatterCache } from './number.pipe';
import { MeasurementViewModel, isTemperatureUOM } from '@cca-infra/common';

// TODO: remove this eslint disable after the check is added
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function unitSpacer(uom: MeasurementViewModel) {
  // TODO add check if the unit is of type celsius, we should not add a extra space then
  if (isTemperatureUOM(uom)) {
    // determine if it is celsius and don't add a space if it is...
  }

  // default case uses a space
  return ` `;
}

export function MeasurementViewModelToString(
  uom: MeasurementViewModel | undefined | null,
  formatter: Intl.NumberFormat,
): string {
  // if null or undefined
  if (!uom || (!uom.value && uom.value !== 0)) {
    return ``;
  }

  // if 2 units
  const firstUnitString = `${formatter.format(uom.value)}${unitSpacer(uom)}${
    uom.symbol
  }`;
  if (uom.secondSymbol && uom.secondValue) {
    return `${firstUnitString} ${formatter.format(uom.value)}${unitSpacer(
      uom,
    )}${uom.symbol}`;
  }

  // default to one unit
  return firstUnitString;
}

@Pipe({
  standalone: true,
  name: 'unit',
  pure: false,
})
export class CdkUnitPipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  private formatterCache = inject(NumberPipeFormatterCache);

  localeSubscription: Subscription | null;
  constructor() {
    super();

    // when locale changes make sure to mark the current view as dirty
    this.localeSubscription = this.localeService.localeChanges$.subscribe(
      () => {
        this.cdr.markForCheck();
      },
    );
  }

  transform(uom: MeasurementViewModel | undefined | null): string {
    // resolve formatter
    const formatter = this.formatterCache.resolveFormatter(
      this.localeService.getLocale(),
    );

    // pass the uom and formatter to the toString util function for uom's
    return MeasurementViewModelToString(uom, formatter);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.localeSubscription?.unsubscribe();

    // Caretaker note: it's important to clean up references to subscriptions since they save the `next`
    // callback within its `destination` property, preventing classes from being GC'd.
    this.localeSubscription = null;
  }
}
