/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = PreTransit  2 = InTransit  3 = Arrived  4 = Completed
 */
export enum OrderLegStatus {
  Undefined = 0,
  PreTransit = 1,
  InTransit = 2,
  Arrived = 3,
  Completed = 4,
}
