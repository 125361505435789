<div>
  @if (shownModality()) {
    <div class="flex flex-col gap-y-1">
      @if (modalitiesWithSuppliers.length > 1) {
        <div>
          <span class="text-xs text-neutral-caption">
            {{
              shortAddress(shownModality()?.stops?.at(0)?.address) +
                ' → ' +
                shortAddress(shownModality()?.stops?.at(-1)?.address)
            }}
          </span>
        </div>
      }
      <div>
        <div>
          <cca-icon icon="building"></cca-icon>
          <span class="whitespace-normal break-words">{{
            shownModality()?.supplierEnterpriseName
          }}</span>
        </div>
        <div>
          <cca-icon icon="group" class="text-base"></cca-icon>
          <span class="whitespace-normal break-words">{{
            shownModality()?.supplierGroupName
          }}</span>
        </div>
      </div>
    </div>
  }
</div>
@if (modalitiesWithSuppliers.length > 1) {
  <span
    class="rounded-xl"
    [ngxTippy]="suppliersTemplate"
    [tippyClassName]="'rounded-xl suppliersTippy'"
    [tippyProps]="{
      placement: 'bottom',
      arrow: true,
      theme: 'light',
      interactive: true,
      appendTo: body,
      allowHTML: true,
      trigger: trigger,
    }"
    class="mt-1 text-sm font-medium text-brand-default"
  >
    {{ showMoreLabel() }}</span
  >
}

<ng-template #suppliersTemplate>
  <div class="min-w-64">
    <h1 class="sticky p-4 pb-2 text-lg font-bold text-neutral-caption">
      {{ supplierGroupsLabel() }}
    </h1>
    @if (showScroll) {
      <mat-divider></mat-divider>
    }

    <div
      class="flex max-h-52 flex-col gap-y-3 overflow-scroll p-4"
      (mouseenter)="showScroll = true"
      (mouseleave)="showScroll = false"
    >
      @for (modality of modalities(); track modality) {
        <div class="flex flex-col gap-y-1">
          <div>
            <span class="text-xs text-neutral-caption">
              {{
                shortAddress(modality.stops?.at(0)?.address) +
                  ' → ' +
                  shortAddress(modality.stops?.at(-1)?.address)
              }}
            </span>
          </div>
          @if (modality.supplierEnterpriseName) {
            <div>
              <div>
                <cca-icon icon="building"></cca-icon>
                <span class="whitespace-normal break-words">{{
                  modality.supplierEnterpriseName
                }}</span>
              </div>
              <div>
                <cca-icon icon="group" class="text-base"></cca-icon>
                <span class="whitespace-normal break-words">{{
                  modality.supplierGroupName
                }}</span>
              </div>
            </div>
          } @else {
            <span class="text-sm warning-text-dark">
              {{ notAssignedText() }}
            </span>
          }
        </div>
      }
    </div>
  </div>
</ng-template>
