export const europeanUnionCountryCodes = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];
export const europeanCountryCodes = Array.from(
  new Set([
    ...europeanUnionCountryCodes,
    'AL',
    'AD',
    'AM',
    'BY',
    'BA',
    'FO',
    'GE',
    'GI',
    'IS',
    'IM',
    'XK',
    'LI',
    'MK',
    'MD',
    'MC',
    'ME',
    'NO',
    'RU',
    'SM',
    'RS',
    'CH',
    'TR',
    'UA',
    'GB',
    'VA',
  ]),
);
