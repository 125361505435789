import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon';
import { CdkDatePipe } from '@cca-common/cdk';

@Component({
  selector: 'cca-date-time',
  standalone: true,
  templateUrl: './date-time.component.html',
  styleUrl: './date-time.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, IconComponent, CdkDatePipe],
})
export class DateTimeComponent {
  @Input() dateTime: number | undefined = undefined;
}
