import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  CreateAddressBookLocationRequest,
  PaginationAddressBookViewModel,
} from '../model';
import {
  AdaptPaginationRequest,
  FilterChoiceOption,
  id,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
} from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class AddressBookService extends BaseApiService {
  constructor() {
    super('location.v1.addressBook');
  }

  addressPaginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<PaginationAddressBookViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  addressPaginatedFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  deleteAddressBookLocation(locationRelationId: id) {
    return this.http.delete(
      `${this.url}delete-address-book-location/${locationRelationId}`,
    );
  }

  updateAddressBookLocation(userSequenceStateId: id) {
    return this.http.post<CreateAddressBookLocationRequest>(
      `${this.url}update-address-book-location`,
      { userSequenceStateId },
    );
  }

  createAddressBookLocation(userSequenceStateId: id) {
    return this.http.post<CreateAddressBookLocationRequest>(
      `${this.url}create-address-book-location`,
      { userSequenceStateId },
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }
}
