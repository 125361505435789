<ng-container *transloco="let t; read: 'bookingDetail'">
  <div class="flex flex-col text-sm">
    <div class="row">
      <cca-icon
        class="text-base"
        [ngClass]="iconClass"
        icon="calendar"
      ></cca-icon>
      @if (startDatetime) {
        <span class="text">
          {{ startDatetime | ccaDate: 'EEE' : timeZone : locale }},
          {{ startDatetime | ccaDate: 'shortDate' : timeZone : locale }}</span
        >
      } @else {
        <span class="text col-span-3">{{
          unknownDateText ? unknownDateText : t('planningInfo.unknownDateText')
        }}</span>
      }
    </div>

    @if (!hideTime) {
      <div class="row">
        <cca-icon
          class="text-base"
          [ngClass]="iconClass"
          icon="clock"
        ></cca-icon>
        @if (startDatetime) {
          @switch (label) {
            @case (PlanningInfoLabel.Between) {
              <span class="text">{{ t('timeInfo.between') }}</span>
              <span class="between-times text">
                <span
                  [matTooltip]="
                    startDatetime
                      | ccaDate: dateFormat : currentTimezone : locale
                  "
                  [matTooltipDisabled]="hideTooltipStartDate"
                  matTooltipPosition="above"
                  >{{ startDatetime | ccaDate: timeFormat : timeZone : locale }}
                </span>
                -
                <span
                  [matTooltip]="
                    endDatetime | ccaDate: dateFormat : currentTimezone : locale
                  "
                  [matTooltipDisabled]="hideTooltipEndDate"
                  matTooltipPosition="above"
                >
                  {{ endDatetime | ccaDate: timeFormat : undefined : locale }}
                </span>
              </span>
              @if (
                startDatetime | transform: diffInDays : endDatetime : true;
                as days
              ) {
                <span class="chip whitespace-nowrap pr-2">
                  {{ t('timeInfo.days', { days: days }) }}
                </span>
              }
            }
            @case (PlanningInfoLabel.At) {
              <span
                class="text"
                [matTooltip]="
                  startDatetime | ccaDate: dateFormat : currentTimezone : locale
                "
                [matTooltipDisabled]="hideTooltipStartDate"
                matTooltipPosition="above"
                >{{ t('timeInfo.at') }}
                {{ startDatetime | ccaDate: 't' : timeZone : locale }}</span
              >
            }
            @case (PlanningInfoLabel.Before) {
              <span
                class="text"
                [matTooltip]="
                  startDatetime | ccaDate: dateFormat : currentTimezone : locale
                "
                [matTooltipDisabled]="hideTooltipStartDate"
                matTooltipPosition="above"
                >{{ t('timeInfo.before') }}
                {{
                  startDatetime | ccaDate: timeFormat : timeZone : locale
                }}</span
              >
            }
            @case (PlanningInfoLabel.After) {
              <span
                class="text"
                [matTooltip]="
                  startDatetime | ccaDate: dateFormat : currentTimezone : locale
                "
                [matTooltipDisabled]="hideTooltipStartDate"
                matTooltipPosition="above"
                >{{ t('timeInfo.after') }}
                {{
                  startDatetime | ccaDate: timeFormat : timeZone : locale
                }}</span
              >
            }
          }
        } @else {
          <span class="text col-span-3">{{
            unknownTimeText
              ? unknownTimeText
              : t('planningInfo.unknownTimeText')
          }}</span>
        }
      </div>
    }

    @if (showRemainingMessage) {
      <div class="pl-6">
        <ng-container>
          <span class="critical-text-light">
            {{ startDatetime | ccaDateRelative }}</span
          >
        </ng-container>
      </div>
    }
  </div>
</ng-container>
