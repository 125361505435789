/**
 * Order API
 * <p>The Order API is used to manage Orders within the platform</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   1 = Angstrom  2 = AstronomicalUnit  3 = Centimeter  4 = Chain  5 = DataMile  6 = Decameter  7 = Decimeter  8 = DtpPica  9 = DtpPoint  10 = Fathom  11 = Foot  12 = Hand  13 = Hectometer  14 = Inch  15 = KilolightYear  16 = Kilometer  17 = Kiloparsec  18 = LightYear  19 = MegalightYear  20 = Megaparsec  21 = Meter  22 = Microinch  23 = Micrometer  24 = Mil  25 = Mile  26 = Millimeter  27 = Nanometer  28 = NauticalMile  29 = Parsec  30 = PrinterPica  31 = PrinterPoint  32 = Shackle  33 = SolarRadius  34 = Twip  35 = UsSurveyFoot  36 = Yard  41 = Megameter  43 = Picometer  44 = Gigameter  45 = Kilofoot  48 = Femtometer
 */
export enum LengthUnit {
  Angstrom = 1,
  AstronomicalUnit = 2,
  Centimeter = 3,
  Chain = 4,
  DataMile = 5,
  Decameter = 6,
  Decimeter = 7,
  DtpPica = 8,
  DtpPoint = 9,
  Fathom = 10,
  Foot = 11,
  Hand = 12,
  Hectometer = 13,
  Inch = 14,
  KilolightYear = 15,
  Kilometer = 16,
  Kiloparsec = 17,
  LightYear = 18,
  MegalightYear = 19,
  Megaparsec = 20,
  Meter = 21,
  Microinch = 22,
  Micrometer = 23,
  Mil = 24,
  Mile = 25,
  Millimeter = 26,
  Nanometer = 27,
  NauticalMile = 28,
  Parsec = 29,
  PrinterPica = 30,
  PrinterPoint = 31,
  Shackle = 32,
  SolarRadius = 33,
  Twip = 34,
  UsSurveyFoot = 35,
  Yard = 36,
  Megameter = 41,
  Picometer = 43,
  Gigameter = 44,
  Kilofoot = 45,
  Femtometer = 48,
}
