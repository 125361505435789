import { id } from './id';

export const enum LocationSource {
  Backend = 0,
  Manual = 1,
  GoogleAutoComplete = 2,
  GoogleMap = 3,
  SequenceBackendAddress = 4,
}

export interface BaseAddress {
  /**
   * What the source is
   */
  source?: LocationSource | null | undefined;

  /**
   * Partial
   */
  partial?: boolean | null | undefined;

  /**
   * Optionally add to address book
   */
  addToAddressBook?: boolean;

  /**
   * Optionally a name
   */
  locationName?: string | null | undefined;

  /**
   * Optionally a id
   */
  locationId?: string | null | undefined;

  /**
   * Address properties
   */
  country: string | null;
  state: string | null;
  zipCode?: string | null;
  countryCode?: string | null;
  stateCode?: string | null;
  city: string | null;
  street: string | null;
  number: string | null;
  numberAddition?: string | null | undefined;
  readonly asText?: string | undefined | null;

  /**
   * Type
   */
  addressType?: string | null | undefined;

  /**
   * longitude latitude
   */
  longitude?: number | null | undefined;
  latitude?: number | null | undefined;
  // openingHours: OpeningHourViewModel[];
  enterpriseGroupLocationId?: string | null;

  /**
   * GeoJson
   */
  geoJson?: string | null;
}

export interface BackendAddress extends BaseAddress {
  source?: LocationSource.Backend | undefined | null;
  locationId?: id | null;
  enterpriseGroupLocationId?: id | null;
  enterpriseGroupName?: string | null;
  addressType?: string | null | undefined;
  timeZoneId?: string;
}

export interface MapAddress extends BaseAddress {
  source: LocationSource.GoogleMap;
  longitude: number | null;
  latitude: number | null;
}

export interface AutoCompleteAddress extends BaseAddress {
  source: LocationSource.GoogleAutoComplete;
}

export interface ManualAddress extends BaseAddress {
  source: LocationSource.Manual;
}

export interface SequenceBackendAddress extends BaseAddress {
  source: LocationSource.SequenceBackendAddress;
}

export interface AddressViewModel {
  readonly source: number;
  componentBody?: string | null;
  street?: string | null;
  number?: string | null;
  numberAddition?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  country?: string | null;
  countryCode?: string | null;
  stateCode?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  locationId: string;
  locationName?: string | null;
  enterpriseGroupLocationId?: string | null;
  addressType?: string | null;
  timeZoneId?: string | null;
  timeZoneName?: string | null;
  addToAddressBook: boolean;
  geoJson?: string | null;
  readonly asText?: string | null;
}

export type ChainCargoAddress =
  | BackendAddress
  | MapAddress
  | AutoCompleteAddress
  | ManualAddress
  | AddressViewModel
  | SequenceBackendAddress;

export type ChainCargoStopAddress = ChainCargoAddress & {
  stopCompleted: boolean;
};

export function isChainCargoAddress(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
): value is BackendAddress | MapAddress | AutoCompleteAddress | ManualAddress {
  if (!value) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  // every property of address is optional therefore we can only check source..
  if (value.source || value.source === LocationSource.Backend) {
    return true;
  }

  return false;
}
