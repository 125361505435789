import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  id,
} from '@cca-infra/common';
import {
  AssignOrderToPlanningUnitRequest,
  ConfirmPlanningRequest,
  PaginatedUnassignedOrdersViewModel,
  PlanningUnitViewModel,
  ResetDraftOrdersRequest,
  UnassignOrderFromPlanningUnitRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class PlanningService extends BaseApiService {
  constructor() {
    super(`trip.v1.planning`);
  }

  orderCardPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
    carrierGroupId: id | null | undefined,
  ) {
    return this.http.post<PaginatedUnassignedOrdersViewModel>(
      `${this.url}paginated-unassigned-orders`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
        carrierGroupId,
      },
    );
  }

  assignOrderToPlanningUnit(body: AssignOrderToPlanningUnitRequest) {
    return this.http.put<PlanningUnitViewModel>(
      `${this.url}assign-order-to-planning-unit`,
      body,
    );
  }

  unassignOrderToPlanningUnit(body: UnassignOrderFromPlanningUnitRequest) {
    return this.http.put<PlanningUnitViewModel>(
      `${this.url}unassign-order-from-planning-unit`,
      body,
    );
  }

  confirmPlanning(body: ConfirmPlanningRequest) {
    return this.http.put<boolean>(`${this.url}confirm-planning`, body);
  }

  resetDraftOrders(body: ResetDraftOrdersRequest) {
    return this.http.put<boolean>(`${this.url}reset-draft-orders`, body);
  }
}
