/**
 * Trip API
 * <p>The Trip API is used to manage trips in combination with orders</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   1 = Vehicle  2 = TrailerOrContainer  3 = Driver
 */
export enum PlanningUnitEntityType {
  Vehicle = 1,
  TrailerOrContainer = 2,
  Driver = 3,
}
