<mat-form-field subscriptSizing="dynamic" class="w-full">
  <cca-icon icon="search" matPrefix></cca-icon>
  <input
    #input
    matInput
    [formControl]="searchField"
    placeholder="{{ searchLabel }}"
    (keyup.enter)="enter.emit(input.value)"
    autocomplete="off"
  />
  <button
    mat-icon-button
    type="button"
    matSuffix
    (click)="clearSearch()"
    [ngClass]="searchField.value?.length ? 'active' : 'inactive'"
  >
    <cca-icon icon="circle-xmark"></cca-icon>
  </button>
</mat-form-field>
