<div
  class="flex h-full flex-row justify-start gap-4 rounded border p-4 surface-neutral-light border-neutral-default"
  [class.active]="active"
  (click)="selected.next()"
>
  <div class="flex h-16 w-16 content-center justify-center">
    <img class="shrink-0" [src]="imageSrc" />
  </div>

  <div class="flex flex-col justify-center gap-1">
    <h4 class="text-base font-bold text-neutral-body">
      {{ translations?.title }}
    </h4>
    <p class="text-xs font-normal text-neutral-caption">
      {{ translations?.description }}
    </p>
  </div>
</div>
