import { ChainCargoAddress, id } from '@cca-infra/common';
import {
  RecurringShipmentModel,
  RecurringShipmentViewModel,
  SequenceTimeOptionsViewModel,
  StopType,
  TimeOptionType,
} from '@cca-infra/sequence-management/v1';

export interface ContainerDetailModel {
  containerNumber: string;
  pinCode?: string;
  expiryDateTimePinCode?: number;
  dropoffLocation?: ChainCargoAddress;
  dropoffReference?: string;
  remarks?: string;
}

export interface ShipmentModel {
  index?: number;
  timeOptionId: id;
  shipperReference?: string;
  stops: ShipmentStopModel[];
  containerDetails?: ContainerDetailModel;
}

export interface ShipmentStopModel {
  timeZoneId?: string;
  id?: number;
  carrierInstruction?: string;
  locationInstruction?: string;
  locationReference?: string;
  stopIndex: number;
  stopType: StopType;
  timeOptionType?: TimeOptionType;
  timeslotRequired: boolean;
  timeslotPreAgreed: boolean;
  requiresTimeSlot?: boolean;
  timeslotWindowEndTime?: number;
  timeslotWindowStartTime?: number;
  windowEndTime?: number;
  windowStartTime: number;
}

export enum BookingService {
  Undefined,
  Lane,
  FTL,
  Courier = 3,
}

export interface Times {
  earliestPickupTime?: string | number | Date;
  latestPickupTime?: string | number | Date;
  earliestDeliveryTime?: string | number | Date;
  latestDeliveryTime?: string | number | Date;
}
export interface CalendarShipmentDialogModel {
  index: number;
  timingOption: SequenceTimeOptionsViewModel;
  timeOptionId: id;
  shipmentOptionType: StopType;
  pickupTimeslotRequired: boolean;
  deliveryTimeslotRequired: boolean;
  pickupDateStart: string | number | Date;
  pickupDateEnd: string | number | Date;
  deliveryDateStart: string | number | Date;
  deliveryDateEnd: string | number | Date;
  startDate: number;
  endDate: number;
  exactDate: number;
  pickupTimeslotPreAgreed?: boolean;
  deliveryTimeslotPreAgreed?: boolean;
  recurring?: RecurringShipmentViewModel[];
  recurringShipmentData?: RecurringShipmentModel;
  stops: ShipmentStopModel[];
}

export interface CalendarShipmentModel extends ShipmentModel {
  index: number;
  shipmentOptionType: StopType;
  recurringShipmentData?: RecurringShipmentModel;
  stops: ShipmentStopModel[];
}
