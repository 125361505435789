import { MeasurementSystemType } from './measurement-system-type';
import { MassUnit } from './mass';
import { AreaUnit } from './area';
import { TemperatureUnit } from './temperature';
import { VolumeUnit } from './volume';
import { LengthUnit } from './length';

// helper type to create a type that makes one property that is optional required instead
type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

/**
 *
 * @param uom to check
 * @returns if it has a uom.areaTypeUnit will notify typescript that the areaUnitType is not optional
 */
export function isAreaUOM(
  uom: MeasurementViewModel,
): uom is WithRequired<MeasurementViewModel, 'areaUnitType'> {
  return uom.areaUnitType !== null && uom.areaUnitType !== undefined;
}

/**
 *
 * @param uom to check
 * @returns if it has a uom.lengthUnitType will notify typescript that the lengthUnitType is not optional
 */
export function isLengthUOM(
  uom: MeasurementViewModel,
): uom is WithRequired<MeasurementViewModel, 'lengthUnitType'> {
  return uom.lengthUnitType !== null && uom.lengthUnitType !== undefined;
}

/**
 *
 * @param uom to check
 * @returns if it has a uom.massUnitType will notify typescript that the massUnitType is not optional
 */
export function isMassUOM(
  uom: MeasurementViewModel,
): uom is WithRequired<MeasurementViewModel, 'massUnitType'> {
  return uom.massUnitType !== null && uom.massUnitType !== undefined;
}

/**
 *
 * @param uom to check
 * @returns if it has a uom.volumeUnitType will notify typescript that the volumeUnitType is not optional
 */
export function isVolumeUOM(
  uom: MeasurementViewModel,
): uom is WithRequired<MeasurementViewModel, 'volumeUnitType'> {
  return uom.volumeUnitType !== null && uom.volumeUnitType !== undefined;
}

/**
 *
 * @param uom to check
 * @returns if it has a uom.temperatureUnitType will notify typescript that the temperatureUnitType is not optional
 */
export function isTemperatureUOM(
  uom: MeasurementViewModel,
): uom is WithRequired<MeasurementViewModel, 'temperatureUnitType'> {
  return (
    uom.temperatureUnitType !== null && uom.temperatureUnitType !== undefined
  );
}

export interface MeasurementViewModel {
  measurementSystemType: MeasurementSystemType;
  lengthUnitType: LengthUnit;
  massUnitType: MassUnit;
  volumeUnitType: VolumeUnit;
  areaUnitType: AreaUnit;
  temperatureUnitType: TemperatureUnit;
  symbol?: string | null;
  value?: number | null;
  secondLengthUnitType: LengthUnit;
  secondSymbol?: string | null;
  secondValue?: number | null;
}
