<div class="flex gap-3">
  <section class="flex min-w-64 flex-col gap-2 @2xl:min-w-96">
    <h3>
      {{ startHeaderTitle }}
    </h3>
    <div>
      <div class="flex flex-row items-center gap-2">
        @if (startHeaderSubtitleIcon) {
          <cca-icon [icon]="startHeaderSubtitleIcon"></cca-icon>
        }
        <span
          [ngClass]="[
            startHeaderSubtitleFontSize,
            startHeaderSubtitleFontWeight,
          ]"
          class="flex gap-1 text-neutral-body"
        >
          {{ startHeaderSubtitle }}
        </span>

        @if (badges?.length !== 0) {
          <div class="flex flex-row gap-2">
            @for (badge of badges; track badge) {
              <cca-label-badge
                flavor="match"
                [labelName]="badge.label"
              ></cca-label-badge>
            }
          </div>
        }
        @if (canHaveTailLift) {
          <div class="flex flex-row gap-1 text-sm">
            <span class="me-1 text-neutral-caption"
              >{{ canHaveTailLiftTitle }}:
            </span>
            <span class="font-bold">
              {{ canHaveTailLiftYes }}
            </span>
          </div>
        }
      </div>

      <div class="flex flex-row items-center gap-2">
        @if (startHeaderIcon) {
          <cca-icon [icon]="startHeaderIcon"></cca-icon>
        }
        <span
          [ngClass]="startHeaderTextFontWeight"
          class="word-break flex max-w-56 gap-1 text-sm text-neutral-body"
        >
          {{ startHeaderText }}
        </span>
      </div>
    </div>

    @if (startSecondSubtitle && startSecondText) {
      <div>
        <div class="flex flex-row items-center gap-2">
          <span
            [ngClass]="[
              startHeaderSubtitleFontSize,
              startHeaderSubtitleFontWeight,
            ]"
            class="flex gap-1 text-neutral-body"
          >
            {{ startSecondSubtitle }}
          </span>
        </div>

        <div class="flex flex-row gap-2">
          <span
            [ngClass]="startHeaderTextFontWeight"
            class="word-break flex max-w-56 gap-1 text-sm text-neutral-body"
          >
            {{ startSecondText }}
          </span>
        </div>
      </div>
    }
  </section>
  <section
    class="flex grow flex-col gap-2"
    [ngClass]="!endHeaderTitle ? 'grow' : 'min-w-64 @2xl:min-w-96'"
  >
    <h3>
      {{ middleHeaderTitle }}
    </h3>

    <span [ngClass]="{ 'mt-6': !middleHeaderTitle }">
      <span
        [ngClass]="[
          middleHeaderSubtitleFontSize,
          middleHeaderSubtitleFontWeight,
        ]"
        class="flex gap-2 text-sm font-medium text-neutral-body"
      >
        @if (middleHeaderFlagUrl) {
          <cca-country-flag
            class="h-4 w-4"
            [url]="middleHeaderFlagUrl"
          ></cca-country-flag>
        }
        {{ middleHeaderSubtitle }}
      </span>
    </span>

    <span
      [ngClass]="startHeaderTextFontWeight"
      class="max-w-56 text-sm text-neutral-body"
    >
      {{ middleHeaderText }}
      @if (middleInfoTooltipText) {
        <cca-icon
          [matTooltip]="middleInfoTooltipText"
          matTooltipClass="multiline-tooltip"
          matTooltipPosition="below"
          icon="circle-exclamation"
          class="ml-3 cursor-pointer text-brand-default"
        >
        </cca-icon>
      }
    </span>
  </section>

  <section class="flex grow flex-col gap-2">
    <h3>
      {{ endHeaderTitle }}
    </h3>

    <span
      [ngClass]="{ 'mt-6': !endHeaderTitle }"
      class="gap-1 text-sm font-medium text-neutral-body"
    >
      {{ endHeaderSubtitle }}
    </span>

    <span
      [ngClass]="startHeaderTextFontWeight"
      class="word-break max-w-56 text-sm text-neutral-body"
    >
      {{ endHeaderText }}
      @if (endInfoTooltipText) {
        <cca-icon
          [matTooltip]="endInfoTooltipText"
          matTooltipClass="multiline-tooltip"
          matTooltipPosition="below"
          icon="circle-exclamation"
          class="ml-3 cursor-pointer text-brand-default"
        >
        </cca-icon>
      }
    </span>
  </section>
</div>
