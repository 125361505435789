/**
 * Event API
 * <p>The Event API is used to log and analyze events.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Mobile platform  0 = Undefined  1 = IOS  2 = Android
 */
export enum Platform {
  Undefined = 0,
  IOS = 1,
  Android = 2,
}
