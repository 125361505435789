import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  FilterChoiceOption,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  id,
} from '@cca-infra/common';
import {
  CarrierLaneDetailViewModel,
  CarrierLanePaginatedItem,
  CarrierLaneRegionViewModel,
  CreateCarrierLanesRequest,
  UpdateCarrierLanesRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class CarrierLaneService extends BaseApiService {
  constructor() {
    super(`location.v1.carrierlane`);
  }

  allPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<CarrierLanePaginatedItem>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  allPaginationFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  getLane(userGroupId: id, topLevelRegionId: id | undefined | null) {
    if (topLevelRegionId) {
      return this.http.get<CarrierLaneDetailViewModel>(
        `${this.url}detail/${userGroupId}/${topLevelRegionId}`,
      );
    } else {
      return this.http.get<CarrierLaneDetailViewModel>(
        `${this.url}detail/${userGroupId}`,
      );
    }
  }

  updateLane(lane: UpdateCarrierLanesRequest) {
    return this.http.post<CarrierLaneRegionViewModel>(
      `${this.url}update`,
      lane,
    );
  }

  createLane(lane: CreateCarrierLanesRequest) {
    return this.http.post<CarrierLaneRegionViewModel>(
      `${this.url}create`,
      lane,
    );
  }

  getFilterOptions(filterKey: string) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}`,
    );
  }
}
