import { Route, PRIMARY_OUTLET } from '@angular/router';

export const findPath = (config: Route[], route: Route): string => {
  // Create a copy of the `config` array to avoid mutating the original
  config = config.slice();

  // Map to track parent-child relationships between routes
  const parent = new Map<Route, Route>();

  // Set to track routes that have already been visited to prevent infinite loops
  const visited = new Set<Route>();

  // Loop through the `config` until it is empty
  while (config.length) {
    // Remove and return the first element from `config`
    const el = config.shift();

    // If there is no element, exit the loop
    if (!el) {
      break;
    }

    // Mark the current element as visited
    visited.add(el);

    // If the current route matches the target route, exit the loop
    if (el === route) {
      break;
    }

    // Get child routes (if any) of the current route
    let children = el.children || [];

    // If the route has loaded configurations with more routes, add them to children
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loadedRoutes = (el as any)._loadedRoutes as Route[];
    if (loadedRoutes) {
      children = children.concat(loadedRoutes);
    }

    // For each child route, if it hasn't been visited, add it to the config queue
    children.forEach((r: Route) => {
      if (visited.has(r)) return; // Skip already visited routes

      // Track the parent of the child route
      parent.set(r, el);

      // Add the child route to the config for future processing
      config.push(r);
    });
  }

  // Initialize an empty path
  let path = '';

  // Start from the target route and build the path backward using the `parent` map
  let current: Route | undefined = route;

  while (current) {
    // Check if the current route is a primary route
    if (isPrimaryRoute(current)) {
      // Append the route path to the overall path (with a leading `/`)
      path = `/${current.path}${path}`;
    } else {
      // For auxiliary routes, use the outlet syntax in the path
      path = `/(${current.outlet}:${current.path}${path})`;
    }

    // Move up to the parent route
    current = parent.get(current);
  }

  // Replace any consecutive slashes in the path with a single slash
  return path.replace(/\/+/g, '/');
};

function isPrimaryRoute(route: Route) {
  return route.outlet === PRIMARY_OUTLET || !route.outlet;
}
