import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { StatusBadgeFlavor } from '@cca-common/core';
import { Status, StatusesCountComponent } from './statuses-bar';
import { UiStatusBadgeComponent } from '../badges';

@Component({
  selector: 'cca-info-header',
  standalone: true,
  imports: [UiStatusBadgeComponent, MatButtonModule, StatusesCountComponent],
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoHeaderComponent {
  @Input() titlePart1: string | null | undefined = null;
  @Input() titlePart2: string | null = null;
  @Input() badgeText: string | null | undefined = null;
  @Input() badgeFlavor: StatusBadgeFlavor = 'neutral';
  @Input() button1Label: string | null = null;
  @Input() button1Disabled = false;
  @Input() button2Label: string | null = null;
  @Input() button2Disabled = false;
  @Input() button3Label: string | null = null;
  @Input() button3Disabled = false;
  @Input() button4Label: string | null = null;
  @Input() button4Disabled = false;
  @Input() statusCountBarData: Status[] | undefined;
  @Input() description: string | null = null;

  @Output() button1Clicked = new EventEmitter<void>();
  @Output() button2Clicked = new EventEmitter<void>();
  @Output() button3Clicked = new EventEmitter<void>();
  @Output() button4Clicked = new EventEmitter<void>();
}
