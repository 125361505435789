import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction,
} from '@angular/core';
import { MatTreeModule } from '@angular/material/tree';
import { TreeControl } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DataSource } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { id } from '@cca-infra/common';
import { NgClass } from '@angular/common';
import { IconComponent } from '../../icon';
import { SpinnerComponent } from '../../spinner';

export interface UserGroupRadioViewModel {
  id?: id;
  name?: string | null | undefined;
  getChildren: Subject<UserGroupRadioViewModel[]>;
  children?: UserGroupRadioViewModel[];
  hasChildren: boolean;
  childCount?: number;
  loading: boolean;
  isEnterprise?: boolean;
  selected?: boolean;
}

@Component({
  selector: 'cca-collapsible-list-radio-button',
  standalone: true,
  imports: [
    MatTreeModule,
    MatButtonModule,
    MatRadioModule,
    IconComponent,
    SpinnerComponent,
    MatCheckboxModule,
    NgClass,
  ],
  templateUrl: './collapsible-list-radio-button.component.html',
  styleUrls: ['./collapsible-list-radio-button.component.scss'],
  // we specifically don't want onPush here, because it might be that the dataSource has a update for new data
  // but any input directly tied to this component is not updated, causing a change detection cycle to not update the view
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CollapsibleListRadioButtonComponent {
  @Input({ required: true })
  treeControl!: TreeControl<UserGroupRadioViewModel>;

  @Input({ required: true })
  dataSource!: DataSource<UserGroupRadioViewModel>;

  @Input({ required: true })
  trackBy!: TrackByFunction<UserGroupRadioViewModel>;

  @Input() selectedId: id | null | undefined = null;

  @Input() enterpriseLabel = '';

  @Output() radioChange = new EventEmitter<UserGroupRadioViewModel>();

  hasChild = (_: number, node: UserGroupRadioViewModel) => node.hasChildren;

  radioToggle(node: UserGroupRadioViewModel) {
    this.radioChange.emit(node);
  }
}
