/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = PartOfShipment  2 = All
 */
export enum CargoPalletizeType {
  None = 0,
  PartOfShipment = 1,
  All = 2,
}
