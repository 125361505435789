import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import {
  STYLES as CROPPER_STYLES,
  ImgCropperConfig,
  ImgCropperErrorEvent,
  ImgCropperEvent,
  LyImageCropper,
  LyImageCropperModule,
  ImgCropperLoaderConfig,
} from '@alyle/ui/image-cropper';
import { LySliderChange, LySliderModule } from '@alyle/ui/slider';
import { LyIconModule } from '@alyle/ui/icon';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { LyTheme2, StyleRenderer, LY_THEME, LY_THEME_NAME } from '@alyle/ui';
import { lyl, ThemeVariables, ThemeRef } from '@alyle/ui';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule, provideTranslocoScope } from '@jsverse/transloco';
import { IconComponent } from '../icon';

export interface CroppedImageObject {
  imageFile: File;
  croppedImage: string | undefined;
}

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(CROPPER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    root: lyl`{
      ${cropper.root} {
        max-width: 700px
        height: 300px
        border-radius: 5px
        border-width: 2px
        border-style: dashed
        border-color:  lightslategray
        background-color:  linen
      }
    }`,
    sliderContainer: lyl`{
      width: 100px
      text-align: center
      max-width: 400px
      margin: 14px
    }`,
    cropResult: lyl`{
      border-radius: 50%
    }`,
  };
};

@Component({
  selector: 'cca-dialog-image-cropper',
  standalone: true,
  imports: [
    FormsModule,
    LyImageCropperModule,
    LySliderModule,
    LyIconModule,
    MatDialogModule,
    IconComponent,
    MatButtonModule,
    TranslocoModule,
  ],
  templateUrl: './dialog-image-cropper.component.html',
  providers: [
    [LyTheme2],
    [StyleRenderer],
    // Theme that will be applied to this module
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
    provideTranslocoScope('image'),
  ],
  styleUrls: ['./dialog-image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogImageCropperComponent implements OnInit {
  public data = inject<{
    fileEvent: Event;
    cropDimensions: number[];
    cropRoundShape: boolean;
    imageData: { url: string; name: string };
  }>(MAT_DIALOG_DATA);
  readonly sRenderer = inject(StyleRenderer);
  private dialogRef = inject(MatDialogRef<DialogImageCropperComponent>);
  classes = this.sRenderer.renderSheet(STYLES, 'root');
  loadedImage: string | undefined;

  croppedImageFile: File | undefined;
  croppedImage?: string;
  rotate: number | undefined;
  scale!: number;
  ready: boolean | undefined;
  minScale!: number;
  math = Math;
  zoomValue!: number;

  @ViewChild(LyImageCropper, { static: true }) cropper:
    | LyImageCropper
    | undefined;
  originalImage: ImgCropperEvent | undefined;

  myConfig: ImgCropperConfig = {
    width: this.data.cropDimensions[0], // Default `250`
    height: this.data.cropDimensions[1], // Default `200`
    round: this.data.cropRoundShape,
    type: 'image/png', // Or you can also use `image/jpeg`
  };

  ngOnInit(): void {
    const config: ImgCropperLoaderConfig = {
      originalDataURL: this.data.imageData.url,
      width: this.data.cropDimensions[0],
      height: this.data.cropDimensions[1],
    };
    setTimeout(() => {
      this.cropper?.loadImage(config);
    }, 100);
  }

  onCropped(e: ImgCropperEvent) {
    this.croppedImage = e.dataURL;
    const dataURItoBlob = (dataURI: string) => {
      const byteString = atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: 'image/png' });
      return blob;
    };
    const base64 = e.dataURL?.split(',')[1];
    const imageName = this.data.imageData.name;
    const imageBlob = dataURItoBlob(base64 ?? '');
    this.croppedImageFile = new File([imageBlob], imageName, {
      type: 'image/png',
    });
  }

  onReady() {
    this.ready = true;
    this.zoomValue = 0;
  }

  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }

  onSliderInput(event: LySliderChange) {
    this.scale = event.value as number;
    this.zoomValue = this.setScale(
      event.value as number,
      this.minScale,
      1,
      0,
      100,
    );
  }

  //method which gets the scale(zoom) value from 0 to 100
  private setScale(
    scale: number,
    inMin: number,
    inMax: number,
    outMin: number,
    outMax: number,
  ) {
    return ((scale - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  onLoaded(e: ImgCropperEvent) {
    this.loadedImage = e.originalDataURL;
    this.originalImage = e;
  }

  saveCrop() {
    this.cropper?.crop();
    this.dialogRef.close({
      img: this.croppedImage,
      imgFile: this.croppedImageFile,
    });
  }

  reset() {
    const config = {
      rotation: 0,
      originalDataURL: `${this.loadedImage}`,
      name: this.originalImage?.name,
      width: this.data.cropDimensions[0],
      height: this.data.cropDimensions[1],
    };
    setTimeout(() => {
      this.cropper?.loadImage(config);
      setTimeout(() => {
        this.cropper?.center();
      }, 100);
    }, 200);
  }
}
