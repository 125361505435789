export enum NoticePeriod {
  Undefined = 0,
  TwelveHoursBeforePickup = 12,
  TwentyFourHoursBeforePickup = 24,
  FortyEightHoursBeforePickup = 48,
  ThreeDaysBeforePickup = 72,
  FourDaysBeforePickup = 96,
  FiveDaysBeforePickup = 120,
  SixDaysBeforePickup = 144,
  SevenDaysBeforePickup = 168,
}
