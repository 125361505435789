import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

export enum Mode {
  Light,
  Dark,
}
@Component({
  selector: 'cca-avatar',
  standalone: true,
  imports: [NgClass],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  Mode = Mode;

  @Input() url?: string | null;
  @Input() initials?: string;
  @Input() mode: Mode = Mode.Light;
  @Input() size!: number;
}
