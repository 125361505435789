export class Base64 {
  static encode(input: string): string {
    // encode base64, based on environment
    const textEncoder = new TextEncoder();
    const bytes: Uint8Array = textEncoder.encode(input);

    // convert byte to char code
    let text = '';
    for (let i = 0; i < bytes.length; i++) {
      text += String.fromCharCode(bytes[i]);
    }
    return btoa(text);
  }

  static decode(base64: string): string {
    // decode base64, based on environment
    const text = atob(base64);

    // convert char into bytes
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = text.charCodeAt(i);
    }

    // decode bytes into string
    const decoder = new TextDecoder(); // default is utf-8
    return decoder.decode(bytes);
  }
}
