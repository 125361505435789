/**
 * Location API
 * <p>The Location API is used to manage Lanes, Addresses and other locations/p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Inbound  2 = Outbound  4 = Both
 */
export enum CarrierCorridorDirection {
  Undefined = 0,
  Inbound = 1,
  Outbound = 2,
  Both = 4,
}
