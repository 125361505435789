import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  inject,
  DestroyRef,
} from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  LayoutModule,
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PushPipe } from '@ngrx/component';
import { NgClass } from '@angular/common';
import { SwaggerComponent } from '../swagger/swagger.component';
import {
  ActivatedRoute,
  Router,
  RouterLinkWithHref,
  RouterOutlet,
  UrlSegment,
  RouterModule,
  NavigationEnd,
} from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { Title } from '@angular/platform-browser';
import { IconComponent } from '@cca/ui';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cca-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  standalone: true,
  imports: [
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    PushPipe,
    NgClass,
    RouterLinkWithHref,
    SwaggerComponent,
    RouterOutlet,
    RouterModule,
    MatExpansionModule,
    IconComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  private breakpointObserver = inject(BreakpointObserver);
  private router = inject(Router);
  private titleService = inject(Title);
  private route = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);

  @ViewChild(MatSidenav) drawer: MatSidenav | undefined;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );
  title = '';
  constructor() {
    this.title = this.titleService.getTitle();
  }

  toggle() {
    if (this.drawer?.mode == 'over') {
      this.drawer.close();
    }
  }

  ngOnInit(): void {
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this.route.firstChild;
          if (child?.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
        this.title = ttl;
      });
  }

  isHomeRoute() {
    return this.router.url === '/home';
  }

  navigate(url: string, route: ActivatedRoute) {
    route.url.pipe(take(1)).subscribe((segments: UrlSegment[]) => {
      const commands = segments.slice(1).map((x) => x.path);
      this.router.navigate([url, ...commands]);
    });
  }
}
