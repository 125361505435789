import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { GroupCO2ViewModel, UpdateGroupCO2Request } from '../model';
import { id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class GroupCO2Service extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getCO2(groupId: id) {
    return this.http.get<GroupCO2ViewModel>(`${this.url}detail/co2/${groupId}`);
  }

  updateCO2(co2Data: UpdateGroupCO2Request) {
    return this.http.post<GroupCO2ViewModel>(`${this.url}update/co2`, co2Data);
  }
}
