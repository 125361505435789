import {
  ChangeDetectionStrategy,
  Component,
  InjectionToken,
  Injector,
  Type,
  inject,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  defaultAllowedFileTypes,
  defaultSelectableFileTypes,
  FileUploadComponent,
} from '../file-upload';
import { MassUploadDialogStore } from './mass-upload-store.interface';
import { IconComponent } from '../../icon';
import { InfoBannerComponent } from '../../info-banner';
import { SpinnerComponent } from '../../spinner';

export type MassUploadTranslationKeys = {
  title: string;
  paragraph: string;
  downloadLinkLabel?: string;
  cancelButton: string;
  saveButton: string;
  validatingParagraph: string;
  uploadSuccessfulParagraph: string;
  uploadFailedParagraph: string;
  someRowsFailed?: string;
};

export interface MassUploadDialogData {
  translations?: MassUploadTranslationKeys | undefined;
  store: Type<MassUploadDialogStore>;
  metaData?: Record<string, unknown>;
  allowedFileTypes?: string[];
  selectableFileTypes?: string;
  isLanesUpload?: boolean;
}

export const massUploadDialogStore = new InjectionToken<MassUploadDialogStore>(
  'MassUploadDialogStore',
);

@Component({
  selector: 'cca-mass-upload-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    SpinnerComponent,
    FileUploadComponent,
    MatButtonModule,
    IconComponent,
    InfoBannerComponent,
  ],
  templateUrl: './mass-upload-dialog.component.html',
  styleUrls: ['./mass-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MassUploadDialogComponent {
  public translations: MassUploadTranslationKeys | undefined;
  private _data: MassUploadDialogData;

  signalType = signal;

  store: MassUploadDialogStore;
  metaData: Record<string, unknown> | undefined;
  allowedFileTypes?: string[] = defaultAllowedFileTypes;
  selectableFileTypes: string = defaultSelectableFileTypes;
  lanesUpload = false;

  constructor() {
    this._data = inject(MAT_DIALOG_DATA);
    this.translations = this._data?.translations;

    if (!this._data.store) {
      throw Error(
        `MassUploadDialogComponent requires setting a store to be used for uploading, the store should implement the following interface "MassUploadDialogStore"`,
      );
    }

    this.metaData = this._data.metaData;
    if (this._data.allowedFileTypes) {
      this.allowedFileTypes = this._data.allowedFileTypes;
    }

    if (this._data.selectableFileTypes) {
      this.selectableFileTypes = this._data.selectableFileTypes;
    }

    if (this._data.isLanesUpload) {
      this.lanesUpload = true;
    }

    // we are receiving a class type here so we need to create a new injector instance with a provider to create a instance of it
    // the provided class might contain constructor based injection which would mean we have to provide those when creating the instance
    // doing it this way, we let angular create a instance for us.
    const injector = inject(Injector);
    this.store = Injector.create({
      providers: [
        {
          provide: massUploadDialogStore,
          useClass: this._data.store,
        },
      ],
      parent: injector,
    }).get(massUploadDialogStore);
  }
}
