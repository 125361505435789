import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { AddressViewModel } from '../model';
import { id } from '@cca-infra/common';

export interface GroupLocations {
  userGroupId: id;
  enterpriseGroupId: id;
  enterpriseGroupAddresses: AddressViewModel[];
}

@Injectable({
  providedIn: 'root',
})
export class LocationsService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getLocations(groupId: id) {
    return this.http.get<GroupLocations>(
      `${this.url}detail/enterprise-addresses/${groupId}`,
    );
  }
}
