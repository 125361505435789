import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable, inject, makeEnvironmentProviders } from '@angular/core';
import { PlatformEnvironmentToken } from '@cca-environment';
import { Observable } from 'rxjs';

@Injectable()
export class PreviewBuildInterceptor implements HttpInterceptor {
  private config = inject(PlatformEnvironmentToken);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const previewFlag = this.config.previewFlag;
    if (previewFlag) {
      req = req.clone({
        params: req.params.append('previewFlag', previewFlag),
      });
    }

    return next.handle(req);
  }
}

export function providePreviewBuildInterceptor() {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PreviewBuildInterceptor,
      multi: true,
    },
  ]);
}
