import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddressHelper } from '@cca-common/core';
import { CdkTextLimiterPipe } from '@cca-common/cdk';
import { ChainCargoAddress, isChainCargoAddress } from '@cca-infra/common';

@Component({
  standalone: true,
  imports: [CdkTextLimiterPipe, MatTooltipModule],
  selector: 'cca-address-text',
  templateUrl: './address-text.component.html',
  styleUrls: ['./address-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAddressTextComponent implements OnChanges {
  @Input() address: ChainCargoAddress | undefined | null | unknown;

  addressFormatLines: string[] = [];
  @Input() maxCharacterLength = 24;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.address) {
      this.addressFormatLines = AddressHelper.formatAddressToLines(
        this.address as ChainCargoAddress,
        {
          excludeLocationName: true,
        },
      );
    }
  }

  get locationName() {
    if (isChainCargoAddress(this.address)) {
      return this.address.locationName;
    }

    return null;
  }
}
