import { Injectable, Injector, Type, signal } from '@angular/core';

export type SidePanelContent = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: Type<any>;
  options?: {
    inputs?: Record<PropertyKey, unknown>;
    injector?: Injector;
  };
}; // | TemplateRef<any>

@Injectable({
  providedIn: 'root',
})
export class SidePanelService<T = unknown> {
  private contentStack: SidePanelContent[] = [];

  private currentContent = signal<SidePanelContent | null>(null);
  private panelData = signal<T | null>(null);

  get content() {
    return this.currentContent.asReadonly();
  }

  get panelDataUpdate() {
    return this.panelData.asReadonly();
  }

  open(
    component: SidePanelContent['component'],
    options?: SidePanelContent['options'],
  ) {
    this.contentStack.push({
      component: component,
      options: options,
    });
    this.updateCurrentContent();
  }

  sendData(data: T) {
    this.panelData.set(data);
  }

  resetPanelData() {
    this.panelData.set(null);
  }

  back(data?: unknown) {
    if (this.contentStack.length > 1) {
      this.contentStack.pop();
      this.updateCurrentContent(data);
    } else {
      this.close();
    }
  }

  close() {
    this.contentStack = [];
    this.currentContent.set(null);
  }

  private updateCurrentContent(data?: unknown) {
    const current = this.contentStack[this.contentStack.length - 1] || null;
    if (current.options?.inputs && data) {
      current.options.inputs = { ...current.options.inputs, ...data };
    }
    this.currentContent.set(current);
  }
}
