/**
 * Trip API
 * <p>The Trip API is used to manage trips in combination with orders</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Pickup  2 = Delivery  4 = Stop  8 = PickupContainer  16 = DropContainer
 */
export enum StopType {
  Undefined = 0,
  Pickup = 1,
  Delivery = 2,
  Stop = 4,
  PickupContainer = 8,
  DropContainer = 16,
}
