import { Directive, ElementRef, OnInit, inject } from '@angular/core';

@Directive({ standalone: true, selector: '[ccaAutofocus]' })
export class AutofocusDirective implements OnInit {
  private el = inject(ElementRef);

  ngOnInit() {
    this.el.nativeElement.focus();
  }
}
