import { Directive, ElementRef, OnDestroy, inject } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

const whitespaceKeyCodes = [
  32,
  160,
  5760,
  8192,
  8192,
  8194,
  8195,
  8196,
  8197,
  8198,
  8199,
  8200,
  8201,
  8202,
  8232,
  8233,
  8239,
  8287,
  12288,
  ' ',
  'Tab',
];

@Directive({
  selector: 'input[ccaPreventWhitespace], textarea[ccaPreventWhitespace]',
  exportAs: 'ccaPreventWhitespace',
  standalone: true,
})
export class CdkPreventWhitespaceDirective implements OnDestroy {
  private elementRef = inject<
    ElementRef<HTMLInputElement | HTMLTextAreaElement>
  >(ElementRef, { host: true });
  private matInput = inject(MatInput, { host: true, optional: true });
  private formControl = inject<MatFormFieldControl<string>>(
    MatFormFieldControl,
    { optional: true },
  );

  get nativeElement(): HTMLInputElement | HTMLTextAreaElement {
    return this.elementRef.nativeElement;
  }

  constructor() {
    this.nativeElement.addEventListener(
      'keydown',
      this.keyDownHandler as EventListener,
      true,
    );
    this.nativeElement.addEventListener(
      'input',
      this.inputHandler as EventListener,
      true,
    );
  }

  keyDownHandler = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }

    const key = event.key || event.code || event.keyCode;
    if (whitespaceKeyCodes.includes(key)) {
      event.preventDefault();
    }
  };

  inputHandler = () => {
    const oldValue = this.nativeElement.value;
    const newValue = this.nativeElement.value.trim();
    if (oldValue !== newValue) {
      this.nativeElement.value = newValue;
    }
  };

  ngOnDestroy() {
    this.nativeElement.onkeydown = null;
    this.nativeElement.oninput = null;
  }
}
