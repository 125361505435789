<div
  class="page-container grid h-full w-full place-items-center pt-12"
  *ngrxLet="translations as t"
>
  <div class="flex w-full flex-col items-center gap-6">
    <div class="h-28 w-28">
      <img [src]="image" />
    </div>
    <h2 class="font-bold">{{ t?.title }}</h2>
    @if (referenceNumber) {
      <p class="-mt-2">
        {{ t?.referenceLabel }}
        <span class="ml-1 font-medium"> {{ referenceNumber }}</span>
      </p>
    }

    <p class="max-w-md flex-shrink text-center">{{ t?.paragraph }}</p>

    <section class="flex justify-center gap-6">
      <button
        mat-stroked-button
        color="primary"
        (click)="secondaryButtonClick.emit()"
      >
        {{ t?.secondaryButtonLabel }}
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="primaryButtonClick.emit()"
      >
        {{ t?.primaryButtonLabel }}
      </button>
    </section>

    @if (t?.subtitle && t?.subtitle?.length) {
      <p class="text-neutral-caption">
        {{ t?.subtitle }}
      </p>
    }
  </div>
</div>
