import { Injectable, inject } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, map } from 'rxjs';
import {
  Language,
  TranslationService,
} from '@cca-infra/translation-management/v1';

@Injectable({
  providedIn: 'root',
})
export class LanguageResolver implements Resolve<string[]> {
  private translationService = inject(TranslationService);

  resolve(): Observable<string[]> {
    return this.translationService
      .getLanguages()
      .pipe(map((languages: Language[]) => languages.map((lang) => lang.name)));
  }
}
