/**
 * @deprecated in favor of DomainGroupType
 */
export enum DomainType {
  Carrier = '9cef2c66-6432-418c-9178-78d3ef3e7004',
  Shipper = '1881f2a2-f8d0-4f0e-a89d-9b6209bde6cd',
  WarehouseCustomer = 'f290b8e5-319c-4eea-88bc-70fac51f89b9',
  WarehouseSupplier = '2930b94e-528f-4566-9343-81b387b3ea2f',
  Internal = '11790c8e-ae7a-4569-8503-e0c622b89728',
  FinanceCustomer = '54d4d88e-329c-4536-9e5a-3ea128ef06f3',
  FinanceSupplier = 'fb49430a-55c6-44b6-bfa6-500aa3d3695b',
  Enterprise = '0cf97c14-f816-4ca5-b343-8dd7e084ba8e',
  Location = '84ded978-4d1e-49ed-81a6-e22d8772feea',
  ManagedTransShipper = 'fb3cec9b-1476-4411-a955-e1664837e4b9',
}

export enum DomainGroupType {
  Undefined = 0,
  General = 1,
  Enterprise = 2,
  WarehouseCustomer = 4,
  Location = 8,
  Carrier = 16,
  Shipper = 32,
  FinanceGeneral = 64,
  FinanceCustomer = 128,
  FinanceSupplier = 256,
  Internal = 512,
  WarehouseSupplier = 1024,
  CarbonSupplier = 2048,
  ManagedTransShipper = 4096,
}
