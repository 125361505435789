import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable, inject, makeEnvironmentProviders } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Observable } from 'rxjs';
import { LoggedInSignalToken } from '../logged-in-signal';

/**
 * Public Language interceptor adds a query param to every backend request, if we are not logged in to signal what language responses should be
 * when someone is logged in, the backend can resolve this by using the authentication data so there is no need for.
 */
@Injectable()
export class PublicLanguageInterceptor implements HttpInterceptor {
  private transloco = inject(TranslocoService);
  private loggedIn = inject(LoggedInSignalToken);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.loggedIn()) {
      req = req.clone({
        params: req.params.append('lang', this.transloco.getActiveLang()),
      });
    }

    return next.handle(req);
  }
}

export function providePublicLanguageInterceptor() {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PublicLanguageInterceptor,
      multi: true,
    },
  ]);
}
