import {
  Pipe,
  PipeTransform,
  OnDestroy,
  Injectable,
  inject,
} from '@angular/core';
import { BaseLocalePipe } from '@jsverse/transloco-locale';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NumberPipeFormatterCache {
  private formatterCache = new Map<string, Intl.NumberFormat>();

  resolveFormatter(locale: string) {
    // check if a formatter exists within the currencyFormatterMap
    // create a new one if it does not
    if (!this.formatterCache.has(locale)) {
      return this.createAndStoreFormatter(locale);
    }

    // return the formatter from the map
    return this.formatterCache.get(locale) as Intl.NumberFormat;
  }

  private createAndStoreFormatter(locale: string) {
    // create new number formatter
    const formatter = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });

    // save the formatter
    this.formatterCache.set(locale, formatter);

    // return reference to the formatter
    return formatter;
  }
}

@Pipe({
  standalone: true,
  name: 'number',
  pure: false,
})
export class CdkNumberPipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  private formatterCache = inject(NumberPipeFormatterCache);

  localeSubscription: Subscription | null;
  constructor() {
    super();

    // when locale changes make sure to mark the current view as dirty
    this.localeSubscription = this.localeService.localeChanges$.subscribe(
      () => {
        this.cdr.markForCheck();
      },
    );
  }

  transform(value: number): string {
    // resolve formatter
    const formatter = this.formatterCache.resolveFormatter(
      this.localeService.getLocale(),
    );

    // format number using the formatter
    return formatter.format(value);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.localeSubscription?.unsubscribe();
    this.localeSubscription = null;
  }
}
