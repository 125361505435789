import { Injectable, InjectionToken, Provider, inject } from '@angular/core';
import { BuildInformation } from './build-info';

export const BUILD_INFO = new InjectionToken<BuildInformation>(
  'BuildInformation',
);

export function provideBuildInfo(buildInformation: BuildInformation) {
  return {
    provide: BUILD_INFO,
    useValue: buildInformation,
  } as Provider;
}

@Injectable({ providedIn: 'root' })
export class BuildInfoService {
  private _buildInfo = inject(BUILD_INFO, {
    optional: true,
  });

  getBuildInformation() {
    return this._buildInfo;
  }

  printBuildInfo() {
    console.group('Build Information');
    console.log(`SHA: ${this.getBuildInformation()?.sha}`);
    console.log(`VERSION: ${this.getBuildInformation()?.version}`);
    console.groupEnd();
  }
}
