import { InjectionToken } from '@angular/core';
import type { PlatformEnvironmentConfiguration } from './environment.interface';

export const PlatformEnvironmentToken =
  new InjectionToken<PlatformEnvironmentConfiguration>('ChainCargoEnvironment');

export function providePlatformEnvironment(
  environment: PlatformEnvironmentConfiguration,
) {
  return {
    provide: PlatformEnvironmentToken,
    useValue: environment,
  };
}
