import { DateTime } from 'luxon';

export interface CCADateTime {
  readonly milliSeconds: number;
  timeZoneId?: string | null;
}

export function isCCADateTime(
  value: CCADateTime | Date | string | number | null | undefined | unknown,
): value is CCADateTime {
  return (
    (value as CCADateTime)?.milliSeconds !== undefined &&
    (value as CCADateTime)?.timeZoneId !== undefined
  );
}

export function convertToDateTime(
  date:
    | number
    | string
    | Date
    | DateTime
    | null
    | undefined
    | CCADateTime
    | unknown,
): DateTime | null {
  if (DateTime.isDateTime(date)) {
    return date;
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (typeof date === 'number') {
    return DateTime.fromMillis(date);
  }

  if (isCCADateTime(date)) {
    return DateTime.fromMillis(date.milliSeconds, {
      zone: date.timeZoneId ?? undefined,
    });
  }

  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }

  return null;
}

export function convertToUTCAndFormatISO(date: Date) {
  return (
    DateTime.fromJSDate(date)
      .toUTC(undefined, {
        keepLocalTime: true,
      })
      .toISO()
      ?.slice(0, -1)
      .toString() ?? ''
  );
}
