/**
 * User API
 * <p>The User API is used to manage users of the platform. Users van login, change passwords.                                      It will also provide an access and refresh token which is used for other API access.</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = Read  2 = Create  4 = Update  8 = Delete  16 = Offer  32 = Refuse  64 = Accept  128 = Reject  256 = Open  512 = Close  1024 = Bid
 */
export enum PermissionType {
  Undefined = 0,
  Read = 1,
  Create = 2,
  Update = 4,
  Delete = 8,
  Offer = 16,
  Refuse = 32,
  Accept = 64,
  Reject = 128,
  Open = 256,
  Close = 512,
  Bid = 1024,
}
