/**
 * Location API
 * <p>The Location API is used to manage Lanes, Addresses and other locations/p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = All  2 = ZipcodeRange
 */
export enum CarrierCorridorValueType {
  None = 0,
  All = 1,
  ZipcodeRange = 2,
}
