<div
  class="h-full rounded border border-solid p-4 surface-neutral-light border-neutral-default"
  [class.!border-brand-default]="checked"
>
  <div class="flex h-full items-center gap-2">
    <mat-radio-button [value]="value" color="primary"></mat-radio-button>
    <img class="mr-2 h-16 w-16" [alt]="title" [src]="image" />
    <div class="flex flex-col gap-1 text-neutral-body">
      <div class="flex items-center">
        <b>{{ title }}</b>
      </div>
      <p class="text-sm">{{ description }}</p>
    </div>
  </div>
</div>
