<ng-container
  *ngTemplateOutlet="showTooltip ? tmp : tmpNoTooltip"
></ng-container>

<ng-template #tmp>
  <span
    [matTooltip]="text"
    matTooltipPosition="above"
    matTooltipHideDelay="2000"
  >
    {{ text }}
  </span>
</ng-template>

<ng-template #tmpNoTooltip>
  <span>
    {{ text }}
  </span>
</ng-template>
