<div
  class="flex flex-col justify-start gap-4 rounded-xl border p-4 surface-neutral-light border-neutral-default"
  [class.critical-border]="showErrorState"
>
  <div
    class="flex cursor-pointer items-center justify-start gap-3"
    (click)="toggleAccordion()"
  >
    <cca-icon
      class="flex text-2xl"
      [icon]="accordionOpened() ? 'chevron-up' : 'chevron-down'"
    ></cca-icon>

    <h4 class="text-base font-medium">{{ accordionHeader }}</h4>

    <div class="ms-auto flex gap-1">
      @if (showErrorState) {
        <div class="flex items-center gap-1 critical-text-light">
          <cca-icon icon="circle-exclamation" class="text-2xl"></cca-icon>
          <span class="text-sm">{{ invalidText() }}</span>
        </div>
      }
      <button mat-icon-button color="warn" (click)="removeItem.emit()">
        <cca-icon icon="trash-can"></cca-icon>
      </button>
    </div>
  </div>

  @if (accordionOpened()) {
    <ng-content></ng-content>
  }
</div>
