import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { map, Observable, of, shareReplay } from 'rxjs';
import { HttpContextBuilder } from '@cca-infra/core';
import {
  CreateSimpleUserRequest,
  DriverInEnterpriseViewModel,
  FilterChoiceOption,
  GetUsersByUserGroupRequest,
  PermissionType,
  UserDetailViewModel,
  UserLinkViewModel,
  UserPaginationItemViewModel,
} from '../model';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  MeasurementSystemTypes,
  id,
} from '@cca-infra/common';
import { RoleId } from '../derived';

export type ResetPasswordBody = {
  userId: id;
  resetPasswordToken: string;
  newPassword: string;
};

/**
 * At certain endpoints we currently give back the UserDetailViewModel to the backend, eg when we update it
 * However this does not work, since some endpoints require you to have a userId set
 * Therefore to remain compatible with previous implementation, we add the userId to the returned Model
 * we can usually derive this by setting object.userId to equal object.id, when userId is not given by backend
 */
export interface UserDetailModel extends UserDetailViewModel {
  userId: UserDetailViewModel['id'];
}

function addUserId(user: UserDetailViewModel) {
  return {
    ...user,
    userId: (user as any)?.userId || user.id,
  } as UserDetailModel;
}

/**
 * TODO: remove interfaces after create-simple endpoint swagger endpoint definitions are correct
 */
export interface UserCreateResponse {
  email: string;
  id: id;
  userName: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseApiService {
  constructor() {
    super(`user.v1.user`);
  }

  operatorsRequest = this.http
    .get<UserDetailViewModel[]>(`${this.url}get-operators`)
    .pipe(
      map((users: UserDetailViewModel[]) => {
        return users.map((user) => addUserId(user));
      }),
      shareReplay(1),
    );

  getOperators() {
    return this.operatorsRequest;
  }

  allPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<UserPaginationItemViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  allPaginationFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  setUserLanguage(language: string) {
    return this.http.post<boolean>(`${this.url}set-language`, {
      languageName: language,
    });
  }

  getUsersFromGroupOrEnterprise(
    userGroupParams: GetUsersByUserGroupRequest = {
      permissionType: PermissionType.Undefined,
      includeAvatar: false,
    },
  ) {
    return this.http
      .post<
        UserDetailViewModel[]
      >(`${this.url}get-all-by-usergroup`, userGroupParams)
      .pipe(
        map((users) => {
          return users.map((user) => addUserId(user));
        }),
      );
  }

  getUserById(userId: id) {
    return this.http
      .get<UserDetailViewModel>(`${this.url}${userId}`, {
        withCredentials: true,
        context: HttpContextBuilder({
          authentication: false,
        }),
      })
      .pipe(map((user) => addUserId(user)));
  }

  getDriversInEnterprise(enterpriseGroupId: id) {
    return this.http.get<DriverInEnterpriseViewModel[]>(
      `${this.url}get-drivers-in-enterprise/${enterpriseGroupId}`,
    );
  }

  getTransportPlannersInEnterprise(enterpriseGroupId: id) {
    return this.http.get<UserDetailViewModel[]>(
      `${this.url}get-transport-planners-in-enterprise/${enterpriseGroupId}`,
    );
  }

  getUserInGroup(groupId: id, roleId: RoleId) {
    return this.http.get<UserDetailViewModel[]>(
      `${this.url}get-users-in-group-by-role/${groupId}/${roleId}`,
    );
  }

  getSalesUsers() {
    return this.http
      .get<UserDetailViewModel[]>(`${this.url}get-sales-users`)
      .pipe(map((users) => users.map((user) => addUserId(user))));
  }

  getTenderOpsUsers() {
    return this.http
      .get<UserDetailViewModel[]>(`${this.url}get-tender-ops-users`)
      .pipe(map((users) => users.map((user) => addUserId(user))));
  }

  createSimpleDriverUser(user: CreateSimpleUserRequest) {
    return this.http.post<UserCreateResponse>(`${this.url}create-simple`, user);
  }

  deleteUser(userId: id) {
    return this.http.delete(`${this.url}${userId}`);
  }

  unDeleteUser(userId: id) {
    return this.http.post(`${this.url}${userId}`, {});
  }

  userExists(userName: string, userGroupId?: string) {
    return this.http.post<boolean>(`${this.url}exists`, {
      userName: userName,
      userGroupId: userGroupId,
    });
  }

  checkUserLink(authHash: string) {
    return this.http.get<UserLinkViewModel>(
      `${this.url}check-user-link/${authHash}`,
    );
  }

  emailAvailable(email: string) {
    return this.http.post<boolean>(`${this.url}email-is-available`, {
      email: email,
    });
  }

  getMeasurementSystemTypes() {
    return this.http.get<MeasurementSystemTypes[]>(
      `${this.url}get-measurement-system-types/`,
    );
  }

  getByPermission(permissionKey: string) {
    return this.http
      .post<UserDetailViewModel[]>(`${this.url}get-by-permission`, {
        permissionKey: permissionKey,
        hideAdmins: true,
      })
      .pipe(map((users) => users.map((user) => addUserId(user))));
  }

  resetPassword(resetPasswordBody: ResetPasswordBody) {
    return this.http.post<{
      userName: string;
    }>(`${this.url}update-password`, resetPasswordBody, {
      context: HttpContextBuilder({
        authentication: false,
      }),
    });
  }

  generatePassword(): Observable<{
    password: string;
  }> {
    return this.http.get<{
      password: string;
    }>(`${this.url}generate-password`);
  }

  assignableToGroupPaginated(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<UserPaginationItemViewModel>>(
      `${this.url}assignable-to-group-paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  assignableToGroupPaginatedFilters() {
    return of([]);
    // return this.http.get(`${this.url}assignable-to-group-paginated-filters`);
  }

  uploadUserAvatar(userId: id, avatarFile: File) {
    const formData: FormData = new FormData();
    formData.set('FormFile', avatarFile);
    formData.set('UserId', userId);

    return this.http.post(`${this.url}upload`, formData);
  }

  getFilterOptions(
    filterKey: string,
    parameters: { filterOptionsGroupTypes: number | undefined },
  ) {
    return this.http.get<FilterChoiceOption[]>(
      `${this.url}get-filter-options/${filterKey}/${parameters.filterOptionsGroupTypes}`,
    );
  }
}
