import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PlatformEnvironmentToken } from '@cca-environment';
import { TranslocoModule } from '@jsverse/transloco';
@Component({
  selector: 'cca-error-page',
  standalone: true,
  imports: [TranslocoModule, MatButtonModule],
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  environment = inject(PlatformEnvironmentToken);
  @Input() title: string | undefined | null;
  @Input() paragraph: string | undefined | null;
  @Input() src: string | undefined | null;
  @Input() showButtons: boolean | undefined | null;
}
