import { Injectable } from '@angular/core';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  id,
} from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import {
  CreateMultipleGroupRelationsRequest,
  GroupRelationType,
  GroupRelationViewModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class RelationService extends BaseApiService {
  constructor() {
    super(`user.v1.relation`);
  }

  relationPagination(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<GroupRelationViewModel>>(
      `${this.url}paginated`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  relationPaginationFilters() {
    return this.http.get(`${this.url}paginated-filters`);
  }

  relationExport(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post(
      `${this.url}paginated-export`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
      {
        responseType: 'arraybuffer',
        observe: 'response',
      },
    );
  }

  getExistingRelations(groupId: id, relationType: GroupRelationType) {
    return this.http.get<GroupRelationViewModel[]>(
      `${this.url}get-all/${groupId}/${relationType}`,
    );
  }

  createRelation(request: CreateMultipleGroupRelationsRequest) {
    return this.http.post(`${this.url}create-multiple`, request);
  }

  deleteRelation(relationId: id) {
    return this.http.delete(`${this.url}delete/${relationId}`);
  }
}
