/**
 * Order API
 * <p>The Order API is used to manage Orders within the platform</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = NotRequired  1 = Pending  2 = Confirmed  -1 = Cancelled
 */
export enum TimeSlotsStatus {
  NotRequired = 0,
  Pending = 1,
  Confirmed = 2,
  Cancelled = -1,
}
