import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { IconComponent } from '../icon';

@Component({
  standalone: true,
  imports: [IconComponent],
  selector: 'cca-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent implements OnInit {
  @Input() name: string | null | undefined;
  @Input() firstName = '';
  @Input() lastName = '';
  @Input() email = '';
  @Input() phone = '';
  @Input() companyName: string | null | undefined;
  @Input() enterpriseName: string | null | undefined;
  @Input() contactType: string | undefined = undefined;
  @Input() shouldShowCompany = true;
  @Input() shouldShowEnterprise = true;
  @Input() avatarURL: string | null | undefined = '';
  @Output() showManagers = new EventEmitter<void>();

  initials = '';

  handleClick() {
    this.showManagers.emit();
  }

  ngOnInit() {
    if (!this.firstName || !this.lastName) {
      if (this.name) {
        //some good ol' fashioned array manipulation
        const splitName = this.name.split(' ');
        this.firstName = splitName[0];
        this.lastName = splitName.slice(1).join(' ');
        this.initials = `${this.firstName?.charAt(0) ?? '?'}${
          this.lastName?.charAt(0) ?? '?'
        }`.toUpperCase();
      }
    } else {
      this.initials = `${this.firstName?.charAt(0) ?? '?'}${
        this.lastName?.charAt(0) ?? '?'
      }`.toUpperCase();
    }
  }
}
