import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CtrlChainIcons, StatusBadgeFlavor } from '@cca-common/core';
import { IconComponent } from '../../icon';

@Component({
  standalone: true,
  imports: [NgClass, IconComponent],
  selector: 'cca-label-badge',
  styleUrls: ['./label-badge.component.scss'],
  templateUrl: './label-badge.component.html',
})
export class UiLabelBadgeComponent {
  @Input() flavor: StatusBadgeFlavor = 'primary';
  @Input() labelName: string | number | boolean = '';
  @Input() icon?: CtrlChainIcons;
}
