import { PaginationRequest } from './request';

export function AdaptPaginationRequest(request: PaginationRequest) {
  return {
    pageIndex: request.page.pageIndex,
    pageSize: request.page.pageSize,
    orderByColumn: request.sort.active,
    orderAscending: request.sort.direction !== 'desc',
    filterDefinitions: request?.filters ?? [],
  };
}
