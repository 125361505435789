import {
  Component,
  ChangeDetectionStrategy,
  inject,
  computed,
  input,
} from '@angular/core';
import { CdkTransformPipe, RouterFacade } from '@cca-common/cdk';
import { TranslocoModule } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { provideTranslocoScope } from '@jsverse/transloco';
import { DomainGroupType } from '@cca-infra/user-management/v1';
import { GroupHeaderDataViewModel } from '@cca-infra/user-management/v2';
import { IconComponent } from '../icon';
import { SectionHeaderComponent } from '../section-header';

@Component({
  standalone: true,
  imports: [
    TranslocoModule,
    MatButtonModule,
    IconComponent,
    SectionHeaderComponent,
    CdkTransformPipe,
  ],
  selector: 'cca-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss'],
  providers: [provideTranslocoScope('group')],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupInfoComponent {
  private router = inject(RouterFacade);
  canUpdateGroup = input.required<boolean>();
  userGroup = input.required<GroupHeaderDataViewModel | null>();
  groupAvatar = computed(() => this.userGroup()?.brandingImageUrl);
  isInternalGroup = computed(() => {
    return (
      this.userGroup()?.groupTypes?.find(
        (x) => x.domainGroupType === DomainGroupType.Internal,
      ) ?? false
    );
  });

  editClick() {
    this.router.navigate([`/groups/edit/${this.userGroup()?.userGroupId}`]);
  }
}
