<div
  class="flex flex-col items-center"
  [ngClass]="smallFormat ? 'pt-10' : 'pt-56'"
>
  <img [ngClass]="smallFormat ? 'h-20' : 'h-40'" [src]="image" />
  @if (text) {
    <span
      [ngClass]="
        smallFormat
          ? 'mt-4 text-lg font-bold text-neutral-disabled'
          : 'mt-6 text-2xl font-bold text-neutral-caption'
      "
      >{{ text }}</span
    >
  }
  @if (subText) {
    <span class="text-base font-normal text-neutral-body">{{ subText }}</span>
  }
  @if (buttonText) {
    <button
      class="mt-6"
      (click)="buttonClicked.emit()"
      mat-flat-button
      color="primary"
    >
      {{ buttonText }}
    </button>
  }
</div>
