import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { IconComponent } from '../icon';
import { DomainType, id } from '@cca-infra/common';
import { NgClass, NgTemplateOutlet } from '@angular/common';

export interface TreeViewNode {
  id: id;
  name?: string | null;
  isSelectable?: boolean;
  parentId?: id;
  children?: TreeViewNode[] | null;
  lastNode?: boolean;
  anyUsersInGroup?: boolean;
  disabled?: boolean;
  expandable?: boolean;
  level?: number;
  currentUserIsInGroup?: boolean;
  isRoot?: boolean;
  isEnterprise?: boolean;
  pointsToFinancialGroup?: boolean;
  billToName?: string | null;
  billToGroupId?: boolean;
  domainGroupTypeIds?: DomainType[];
}

interface TemplateContext {
  $implicit: TreeViewNode;
}

@Component({
  selector: 'cca-tree-view',
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    IconComponent,
  ],
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeViewComponent implements OnChanges {
  @ContentChild('editMenuTemplate')
  editMenuTemplate!: TemplateRef<TemplateContext>;
  @ContentChild('tailTemplate') tailTemplate!: TemplateRef<TemplateContext>;

  @Input() set groupData(value: TreeViewNode[]) {
    this.dataSource.data = value;
    this.treeControl.dataNodes = value;
  }
  @Input() selectedNode: string | null = null;
  @Input() showSelectableRadioBtn = true;
  @Output() nodeSelected = new EventEmitter<MatRadioChange>();

  treeControl = new NestedTreeControl<TreeViewNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<TreeViewNode>();

  ngOnChanges() {
    if (this.selectedNode) {
      this.expand(this.dataSource.data, this.selectedNode);
    } else {
      this.treeControl.expandAll();
    }
  }

  expand(data: TreeViewNode[], selectedNodeId: id) {
    if (data) {
      data.forEach((node) => {
        const hasSelectedChild = node.children?.map((c) =>
          JSON.stringify(c).includes('"id":"' + selectedNodeId),
        );
        if (hasSelectedChild?.includes(true)) {
          this.treeControl.expand(node);
          if (node.children) {
            this.expand(node.children, selectedNodeId);
          }
        }
      });
    }
  }

  hasChild = (_: number, node: TreeViewNode) =>
    !!node.children && node.children.length > 0;
}
