@if (address) {
  @if (locationName) {
    <div class="break-words font-medium">
      {{ locationName }}
    </div>
  }
  @for (addressLine of addressFormatLines; track addressLine) {
    <div
      [matTooltip]="addressLine"
      [matTooltipDisabled]="addressLine.length <= maxCharacterLength"
      matTooltipPosition="above"
    >
      {{ addressLine | textLimiter: maxCharacterLength }}
    </div>
  }
}
