export enum AuditValueDisplayType {
  Text = 0,
  Number = 1,
  Currency = 2,
  Date = 3,
  DateTime = 4,
  Address = 5,
  User = 6,
  UserGroup = 7,
  Vehicle = 8,
  NotNull = 9,
}

export interface AuditLogItemViewModel {
  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  auditTimeStamp: string;
  auditTimeStampMilliseconds: number;
  oldValue?: string | null;
  newValue?: string | null;
  entityId: string;
  entityType?: string | null;
  objectName?: string | null;
  translatedIdentifier?: string | null;
  fieldName?: string | null;
  oldDisplayValue?: string | null;
  newDisplayValue?: string | null;
  saveSourceType?: string | null;
  auditValueDisplayType: AuditValueDisplayType;
}
