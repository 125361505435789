import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable, makeEnvironmentProviders } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceWorkerContextToken } from '../context';
import { PlatformEnvironmentToken } from '@cca-environment';

@Injectable()
export class ByPassServiceWorkerInterceptor implements HttpInterceptor {
  private config = inject(PlatformEnvironmentToken);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const isServiceWorkerContext = req.context.get(ServiceWorkerContextToken);

    if (
      this.config.production &&
      !isServiceWorkerContext &&
      !req.headers.has('ngsw-bypass')
    ) {
      req = req.clone({
        setHeaders: {
          'ngsw-bypass': 'true',
        },
      });
    }

    return next.handle(req);
  }
}

export function provideBypassServiceWorkerInterceptor() {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ByPassServiceWorkerInterceptor,
      multi: true,
    },
  ]);
}
