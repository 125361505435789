import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { NextPreviousScrollableDirective } from './next-previous-scrollable.directive';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-next-previous-scrollbar',
  standalone: true,
  templateUrl: './next-previous-scrollbar.component.html',
  styleUrl: './next-previous-scrollbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NextPreviousScrollableDirective, IconComponent],
})
export class NextPreviousScrollbarComponent {
  @Input() scrollUnit = 200;
}
