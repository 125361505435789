import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import { Observable } from 'rxjs';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  id,
} from '@cca-infra/common';
import {
  GroupOrderActionItemViewModel,
  V2EnterpriseGroupViewModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class GroupsOrderActionsService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  paginatedUserGroupOrderACtions(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ): Observable<PaginationResponse<GroupOrderActionItemViewModel>> {
    return this.http.post<PaginationResponse<GroupOrderActionItemViewModel>>(
      `${this.url}paginated-for-order-actions`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  getGroupsForOrderActions(
    enterpriseGroupId: id,
    domainType: number,
    orderActionTypeId: id,
  ) {
    return this.http.post<V2EnterpriseGroupViewModel[]>(
      `${this.url}get-groups-in-enterprise-for-order-actions`,
      {
        enterpriseId: enterpriseGroupId,
        domainGroupType: domainType,
        orderActionTypeId: orderActionTypeId,
      },
    );
  }
}
