import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-accordion',
  standalone: true,
  imports: [IconComponent, MatButtonModule],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  @Input() accordionHeader!: string;
  @Input() accordionDeleteButton!: string;
  @Input() showErrorState = false;
  invalidText = input.required<string>();

  @Output() removeItem = new EventEmitter();

  accordionOpened = signal(true);

  toggleAccordion() {
    this.accordionOpened.set(!this.accordionOpened());
  }
}
