import { inject, Injectable } from '@angular/core';
import { PreloadingStrategy, Route, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { PreloadRegistryService } from './registry.service';
import { findPath } from './find-path';

@Injectable()
export class HoverPreloadStrategy implements PreloadingStrategy {
  private router = inject(Router);
  private registry = inject(PreloadRegistryService);
  private loadingSet = new Set<Route>();

  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    // Don't preload if it was already loaded or being loaded.
    if (this.loadingSet.has(route)) {
      return EMPTY;
    }

    // Prevent from preloading if route has data and property is strictly false
    if (route.data && route.data.preload === false) {
      return EMPTY;
    }

    // Find path and check if should prefetch, if so then load()
    const path = findPath(this.router.config, route);
    if (this.registry.shouldPrefetch(path)) {
      this.loadingSet.add(route);

      // We should not remove the route from `loadingSet` else we might re-trigger the same load
      return load();
    }

    // If nothing happens we should still return Empty
    return EMPTY;
  }
}
