import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export type InformationTileTranslations = {
  title: string;
  description: string;
};

@Component({
  selector: 'cca-information-tile',
  standalone: true,
  imports: [],
  templateUrl: './information-tile.component.html',
  styleUrls: ['./information-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationTileComponent {
  @Input() translations!: InformationTileTranslations | undefined;
  @Input() imageSrc!: string;
  @Input() active = false;
  @Output() selected = new EventEmitter<void>();
}
