/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = No  1 = Yes  2 = YesUnder1000  3 = YesOver1000
 */
export enum HazardousShipmentType {
  No = 0,
  Yes = 1,
  YesUnder1000 = 2,
  YesOver1000 = 3,
}
