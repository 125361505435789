import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { StatusBadgeFlavor } from '@cca-common/core';
import { IconComponent } from '../../icon';

@Component({
  standalone: true,
  imports: [NgClass, IconComponent],
  selector: 'cca-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class UiStatusBadgeComponent {
  @Input() flavor: StatusBadgeFlavor = 'primary';
}
