/**
 * Translation API
 * <p>The Translation API is used to manage translations for the entire platform</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Unknown  1 = BackEnd  2 = FrontEnd  4 = TMSMobile  8 = WMSMobile
 */
export enum KeyUsageType {
  Unknown = 0,
  BackEnd = 1,
  FrontEnd = 2,
  TMSMobile = 4,
  WMSMobile = 8,
}
