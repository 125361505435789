/**
 * Tender API
 * <p>The Tender API is used to manage Tenders</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   1 = Centigram  2 = Decagram  3 = Decigram  4 = EarthMass  5 = Grain  6 = Gram  7 = Hectogram  8 = Kilogram  9 = Kilopound  10 = Kilotonne  11 = LongHundredweight  12 = LongTon  13 = Megapound  14 = Megatonne  15 = Microgram  16 = Milligram  17 = Nanogram  18 = Ounce  19 = Pound  20 = ShortHundredweight  21 = ShortTon  22 = Slug  23 = SolarMass  24 = Stone  25 = Tonne  29 = Picogram  35 = Femtogram
 */
export enum MassUnit {
  Centigram = 1,
  Decagram = 2,
  Decigram = 3,
  EarthMass = 4,
  Grain = 5,
  Gram = 6,
  Hectogram = 7,
  Kilogram = 8,
  Kilopound = 9,
  Kilotonne = 10,
  LongHundredweight = 11,
  LongTon = 12,
  Megapound = 13,
  Megatonne = 14,
  Microgram = 15,
  Milligram = 16,
  Nanogram = 17,
  Ounce = 18,
  Pound = 19,
  ShortHundredweight = 20,
  ShortTon = 21,
  Slug = 22,
  SolarMass = 23,
  Stone = 24,
  Tonne = 25,
  Picogram = 29,
  Femtogram = 35,
}
