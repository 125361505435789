// UOM
export enum MeasurementSystemType {
  Metric = 0,
  ImperialUS = 1,
}

export type MeasurementSystemTypes = {
  hasBeenTranslated: boolean;
  measurementSystemType: string;
  measurementSystemTypeValue: number;
  translateGroup: string;
};
