import { isDevMode, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DateTime, Zone } from 'luxon';
import { Locale, BaseLocalePipe } from '@jsverse/transloco-locale';
import { CCADateTime, isCCADateTime } from '@cca-infra/common';

export type DatePipeInput =
  | CCADateTime
  | DateTime
  | Date
  | string
  | number
  | undefined
  | null;
const defaultLuxonFormat = 'fff';

function resolveFormatAlias(
  format: string | undefined | null,
): string | undefined {
  switch (format) {
    case 'long':
    case 'longDate':
      return 'ff';
    case 'short':
    case 'shortDate':
      return 'DD';
    default:
      return format ?? undefined;
  }
}

const localeAwareTokens = [
  // day of week tokens:
  'c',
  'E',
  'ccc',
  'EEE',
  'cccc',
  'EEEE',
  'ccccc',
  'EEEEE',

  // locale aware tokens:
  'D',
  'DD',
  'DDD',
  'DDDD',
  't',
  'tt',
  'ttt',
  'tttt',
  'T',
  'TT',
  'TTT',
  'TTTT',
  'f',
  'ff',
  'fff',
  'ffff',
  'F',
  'FF',
  'FFF',
  'FFFF',

  // current iana zone
  'z',

  // offsets from timezone
  'Z',
  'ZZ',
  'ZZZ',
  'ZZZZ',
  'ZZZZZ',
];
function checkFormat(format: string) {
  const tokens = [...format.matchAll(/\b[A-Za-z]+\b/g)].map((reg) => reg[0]);
  if (tokens.some((x) => !localeAwareTokens.includes(x))) {
    console.warn(
      `ccaDatePipe received a format of "${format}", which might not be locale aware causing a bug in other locales then your own`,
    );
  }
}

@Pipe({
  standalone: true,
  name: 'ccaDate',
  pure: true,
})
export class CdkDatePipe
  extends BaseLocalePipe
  implements PipeTransform, OnDestroy
{
  transform(
    value: DatePipeInput,
    format?: string | null | undefined,
    timezone?: string | Zone | null | undefined,
    locale?: Locale | null | undefined,
  ) {
    format = format ?? undefined;
    timezone = timezone ?? undefined;
    locale = locale ?? undefined;

    return this.formatDate(value, format, timezone, this.getLocale(locale));
  }

  formatDate(
    value: DatePipeInput,
    format?: string | null | undefined,
    timezone?: string | Zone | null | undefined,
    locale?: Locale | null | undefined,
  ) {
    if (value) {
      let dateTime: DateTime | undefined;
      let usedLocale = locale;
      const usedFormat = resolveFormatAlias(format) ?? defaultLuxonFormat;

      if (isDevMode()) {
        checkFormat(usedFormat);
      }

      if (isCCADateTime(value)) {
        const usedTimeZone = timezone ?? value.timeZoneId;
        dateTime = DateTime.fromMillis(value.milliSeconds, {
          zone: usedTimeZone ?? undefined,
        });
      } else if (DateTime.isDateTime(value)) {
        dateTime = DateTime.fromMillis(value.valueOf(), {
          zone: timezone ?? value.zone,
        });
        usedLocale = locale ?? value?.locale;
      } else if (typeof value === 'number') {
        dateTime = DateTime.fromMillis(value.valueOf(), {
          zone: timezone ?? undefined,
        });
      } else if (typeof value === 'string') {
        dateTime = DateTime.fromISO(value.valueOf(), {
          zone: timezone ?? undefined,
        });
      } else if (value instanceof Date) {
        dateTime = DateTime.fromJSDate(value, {
          zone: timezone ?? undefined,
        });
      }

      if (dateTime) {
        try {
          return dateTime.toFormat(usedFormat, {
            locale: usedLocale ?? undefined,
          });
        } catch (e) {
          console.warn(e);
          if (usedLocale?.includes('_')) {
            return dateTime.toFormat(usedFormat, {
              locale: usedLocale?.split('_')[0] ?? undefined,
            });
          }
        }
      }
    }

    return '';
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
