import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  AdaptPaginationRequest,
  PaginationRequest,
  PaginationRequestParameters,
  PaginationResponse,
  id,
} from '@cca-infra/common';
import {
  GetCarrierEnterprisesForTenderRequestV2,
  GetCarrierGroupsForTenderRequestV2,
  GroupEnterpriseDataViewModel,
  ReadEnterpriseGroupsRequest,
  ReadSubgroupsInEnterpriseRequest,
  SimpleEnterpriseGroupsViewModel,
  TenderCarrierGroupViewModel,
  UpdateGroupEnterpriseDataRequest,
  V2EnterpriseGroupsViewModel,
} from '../model';

export interface TenderUserGroupRequestModel {
  vehicleTypeId: string;
  trailerTypeId: string | null;
  fromCountryCode: string;
  toCountryCode: string;
  fromStateCode: string;
  toStateCode: string;
  requestId: string;
  orderId: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class GroupEnterpriseDetailsService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getEnterpriseDetails(groupId: id) {
    return this.http.get<GroupEnterpriseDataViewModel>(
      `${this.url}detail/enterprise-data/${groupId}`,
    );
  }

  updateEnterpriseDetails(enterpriseData: UpdateGroupEnterpriseDataRequest) {
    return this.http.post<GroupEnterpriseDataViewModel>(
      `${this.url}update/enterprise-data`,
      enterpriseData,
    );
  }

  getPaginatedEnterpriseGroups(
    paginationRequest: PaginationRequest,
    extraParams: PaginationRequestParameters,
  ) {
    return this.http.post<PaginationResponse<V2EnterpriseGroupsViewModel>>(
      `${this.url}get-paginated-enterprise-groups`,
      {
        ...AdaptPaginationRequest(paginationRequest),
        ...extraParams,
      },
    );
  }

  getEnterpriseGroups(request: ReadEnterpriseGroupsRequest) {
    return this.http.post<Array<SimpleEnterpriseGroupsViewModel>>(
      `${this.url}get-enterprise-groups`,
      request,
    );
  }

  getCarrierEnterpriseGroupsForTender(
    request: GetCarrierEnterprisesForTenderRequestV2,
  ) {
    return this.http.post<TenderCarrierGroupViewModel[]>(
      `${this.url}get-carrier-enterprises-for-tender`,
      request,
    );
  }

  getGroupsForTender(request: GetCarrierGroupsForTenderRequestV2) {
    return this.http.post<TenderCarrierGroupViewModel[]>(
      `${this.url}get-carrier-groups-for-tender`,
      request,
    );
  }

  getSubgroupsInEnterprise(request: ReadSubgroupsInEnterpriseRequest) {
    return this.http.post<SimpleEnterpriseGroupsViewModel[]>(
      `${this.url}get-subgroups-in-enterprise`,
      request,
    );
  }
}
