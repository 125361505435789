import { Injectable } from '@angular/core';
import { id } from '@cca-infra/common';
import { BaseApiService } from '@cca-infra/core';
import {
  CreatePlanningUnitRequest,
  FilterInformation,
  FilterSetViewModel,
  PaginatedPlanningUnitsRequest,
  PaginatedPlanningUnitsViewModel,
  UpdatePlanningUnitRequest,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class PlanningUnitsService extends BaseApiService {
  constructor() {
    super(`trip.v1.planningUnits`);
  }

  getPlanningUnits(body: PaginatedPlanningUnitsRequest) {
    return this.http.post<PaginatedPlanningUnitsViewModel>(
      `${this.url}get-planning-units`,
      body,
    );
  }

  deletePlanningUnit(planningUnitId: id) {
    return this.http.delete<boolean>(
      `${this.url}delete-planning-unit/${planningUnitId}`,
    );
  }

  createPlanningUnit(body: CreatePlanningUnitRequest) {
    return this.http.post<string>(`${this.url}create-planning-unit`, body);
  }

  updatePlanningUnit(body: UpdatePlanningUnitRequest) {
    return this.http.put<boolean>(`${this.url}update-planning-unit`, body);
  }

  getPlanningUnitFilters() {
    return this.http.get<FilterSetViewModel>(`${this.url}filters`);
  }

  getPlanningUnitFilterOptions(
    filterKey: string,
    parameters: { carrierGroupId: id },
  ) {
    return this.http.get<FilterInformation>(
      `${this.url}get-filter-options/${filterKey}/${parameters.carrierGroupId}`,
    );
  }
}
