<ng-container>
  <header>
    <div class="mr-auto flex flex-col gap-1">
      <div class="mr-auto flex gap-4">
        <h1 class="self-center text-xl font-medium">
          <span class="text-neutral-body">
            {{ titlePart1 }}
          </span>
          <span class="text-neutral-subtitle">
            {{ titlePart2 }}
          </span>
        </h1>
        @if (badgeText) {
          <cca-status-badge class="self-center" [flavor]="badgeFlavor">
            {{ badgeText }}
          </cca-status-badge>
        }
      </div>
      @if (description) {
        <div class="flex w-full text-neutral-subtitle">{{ description }}</div>
      }
    </div>
    @if (statusCountBarData?.length) {
      <div>
        <cca-statuses-count [statuses]="statusCountBarData">
        </cca-statuses-count>
      </div>
    }
    @if (button1Label) {
      <button
        type="button"
        class="mr-3 self-center"
        mat-stroked-button
        [disabled]="button1Disabled"
        color="accent"
        (click)="button1Clicked.emit()"
      >
        {{ button1Label }}
      </button>
    }
    @if (button2Label) {
      <button
        type="submit"
        class="self-center"
        mat-flat-button
        color="primary"
        [disabled]="button2Disabled"
        (click)="button2Clicked.emit()"
      >
        {{ button2Label }}
      </button>
    }
    @if (button3Label) {
      <button
        type="submit"
        class="mr-3 self-center"
        mat-stroked-button
        color="accent"
        [disabled]="button3Disabled"
        (click)="button3Clicked.emit()"
      >
        {{ button3Label }}
      </button>
    }
    @if (button4Label) {
      <button
        type="submit"
        class="self-center"
        mat-flat-button
        color="primary"
        [disabled]="button4Disabled"
        (click)="button4Clicked.emit()"
      >
        {{ button4Label }}
      </button>
    }
  </header>
</ng-container>
