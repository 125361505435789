import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CtrlChainIcons } from '@cca-common/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { IconComponent } from '../../icon';
import { UiLabelBadgeComponent } from '../../badges';
import { CountryFlagComponent } from '../../country-flag';

export type BadgeInfo = {
  label: string;
  color: string;
};

@Component({
  selector: 'cca-overview-info-row',
  standalone: true,
  templateUrl: './overview-info-row.component.html',
  styleUrls: ['./overview-info-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    IconComponent,
    MatTooltipModule,
    UiLabelBadgeComponent,
    CountryFlagComponent,
  ],
})
export class OverviewInfoRowComponent {
  @Input() startHeaderTitle!: string;
  @Input() startHeaderSubtitle?: string;
  @Input() startHeaderSubtitleFontSize = 'text-sm';
  @Input() startHeaderSubtitleFontWeight = 'font-medium';
  @Input() startHeaderSubtitleIcon?: CtrlChainIcons;
  @Input() badges?: BadgeInfo[];
  @Input() canHaveTailLift = false;
  @Input() canHaveTailLiftTitle?: string;
  @Input() canHaveTailLiftYes?: string;
  @Input() startHeaderText?: string | null;
  @Input() startHeaderTextFontWeight?: string = 'font-normal';
  @Input() startHeaderIcon?: CtrlChainIcons;
  @Input() startSecondSubtitle?: string;
  @Input() startSecondText?: string | null;
  @Input() middleHeaderTitle?: string = '';
  @Input() middleHeaderSubtitle?: string | null;
  @Input() middleHeaderText?: string | null;
  @Input() middleHeaderFlagUrl?: string | null;
  @Input() middleHeaderSubtitleFontSize = 'text-sm';
  @Input() middleHeaderSubtitleFontWeight = 'font-medium';
  @Input() endHeaderTitle?: string = '';
  @Input() endHeaderSubtitle?: string | null;
  @Input() endHeaderText?: string | null;
  @Input() infoLink?: string;
  @Input() middleInfoTooltipText?: string | null;
  @Input() endInfoTooltipText?: string | null;
}
