/**
 * Order API
 * <p>The Order API is used to manage Orders within the platform</p>
 *
 * The version of the OpenAPI document: 2.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = NotInvoiced  2 = Invoiced  3 = PartiallyInvoiced  4 = Paid
 */
export enum InvoiceStatusType {
  Undefined = 0,
  NotInvoiced = 1,
  Invoiced = 2,
  PartiallyInvoiced = 3,
  Paid = 4,
}
