/**
 * Trip API
 * <p>The Trip API is used to manage trips in combination with orders</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   1 = Acre  2 = Hectare  3 = SquareCentimeter  4 = SquareDecimeter  5 = SquareFoot  6 = SquareInch  7 = SquareKilometer  8 = SquareMeter  9 = SquareMicrometer  10 = SquareMile  11 = SquareMillimeter  12 = SquareNauticalMile  13 = SquareYard  14 = UsSurveySquareFoot
 */
export enum AreaUnit {
  Acre = 1,
  Hectare = 2,
  SquareCentimeter = 3,
  SquareDecimeter = 4,
  SquareFoot = 5,
  SquareInch = 6,
  SquareKilometer = 7,
  SquareMeter = 8,
  SquareMicrometer = 9,
  SquareMile = 10,
  SquareMillimeter = 11,
  SquareNauticalMile = 12,
  SquareYard = 13,
  UsSurveySquareFoot = 14,
}
