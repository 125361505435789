<button
  type="button"
  (click)="selectSuggestedLocation.emit(suggestedLocation())"
  class="flex w-full items-center gap-4 border-l-2 border-solid border-l-white px-4 py-2 text-left hover:border-l-cca-green-600 hover:surface-neutral-default"
>
  <div class="flex h-10 w-10 content-center justify-center">
    <img class="shrink-0" [src]="locationType() | transform: getLocationIcon" />
  </div>
  <div class="flex flex-col whitespace-nowrap">
    <span class="text-cca-label-md text-neutral-body">{{
      getLocationTypeTranslation(locationType()) | transloco
    }}</span>
    <span class="text-cca-base-sm text-neutral-caption">{{
      description()
    }}</span>
  </div>
  <div class="flex min-w-16 flex-grow items-center justify-end">
    @if (activeLocation()) {
      <cca-icon icon="check" class="text-2xl text-brand-default"></cca-icon>
    }
  </div>
</button>
