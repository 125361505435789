import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'cca-radio-button-card',
  standalone: true,
  imports: [MatRadioModule],
  templateUrl: './radio-button-card.component.html',
  styleUrls: ['./radio-button-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonCardComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() image = '';
  @Input({ required: true }) value: number | undefined;
  @Input({ required: true }) checked = false;
}
