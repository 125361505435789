import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  inject,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[ccaNextPreviousScrollbar]',
  exportAs: 'ccaNextPreviousScrollbar',
})
export class NextPreviousScrollableDirective {
  private elementRef = inject(ElementRef);

  @Input() scrollUnit = 0;

  private get element() {
    return this.elementRef.nativeElement;
  }

  get isOverflow() {
    return this.element.scrollWidth > this.element.clientWidth;
  }

  scroll(direction: number) {
    this.element.scrollLeft += this.scrollUnit * direction;
  }

  get canScrollStart() {
    return this.element.scrollLeft > 0;
  }

  get canScrollEnd() {
    return (
      this.element.scrollLeft + this.element.clientWidth <=
      this.element.scrollWidth
    );
  }

  @HostListener('window:resize')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onWindowResize() {} // required for update view when windows resized
}
