import { Injectable } from '@angular/core';
import { BaseApiService } from '@cca-infra/core';
import {
  AccountingConnectionsViewModel,
  DeleteAccountingConnectionRequest,
  DomainGroupType,
  SetUserGroupExactIdRequestV2,
  UpdateGroupFinanceBankDetailsRequest,
  UpdateGroupFinanceCustomerDetailsRequest,
  UpdateGroupFinanceInvoicingDetailsRequest,
} from '../model';
import { id } from '@cca-infra/common';

@Injectable({
  providedIn: 'root',
})
export class GroupFinanceService extends BaseApiService {
  constructor() {
    super(`user.v2.userGroup`);
  }

  getFinanceGroupsFromEnterprise(
    userGroupId: id | null,
    enterpriseGroupId: id,
    domainGroupType: DomainGroupType,
  ) {
    return this.http.post<{ id: id; name: string }[]>(
      `${this.url}get-finance-groups-from-enterpriseGroup`,
      { userGroupId, enterpriseGroupId, domainGroupType },
    );
  }

  updateGroupFinanceInvoicingBillTo(
    invoicingDetails: UpdateGroupFinanceInvoicingDetailsRequest,
  ) {
    return this.http.post(
      `${this.url}update/finance/invoicing`,
      invoicingDetails,
    );
  }

  updateGroupFinanceInvoicingBillFrom(
    invoicingDetails: UpdateGroupFinanceInvoicingDetailsRequest,
  ) {
    return this.http.post(
      `${this.url}update/finance/bill-from`,
      invoicingDetails,
    );
  }

  updateGroupFinanceBankDetails(
    bankDetails: UpdateGroupFinanceBankDetailsRequest,
  ) {
    return this.http.post(
      `${this.url}update/finance/bank-details`,
      bankDetails,
    );
  }

  updateGroupCustomerFinancials(
    basicInfo: UpdateGroupFinanceCustomerDetailsRequest,
  ) {
    return this.http.post(
      `${this.url}update/finance/customer-details`,
      basicInfo,
    );
  }

  getAccountingConnections(groupId: id) {
    return this.http.get<AccountingConnectionsViewModel>(
      `${this.url}get-accounting-connections/${groupId}`,
    );
  }

  setAccountingConnection(
    setUserGroupExactIdRequest: SetUserGroupExactIdRequestV2,
  ) {
    return this.http.post(
      `${this.url}set-accounting-connection`,
      setUserGroupExactIdRequest,
    );
  }

  deleteAccountingConnection(
    deleteAccountingConnectionRequest: DeleteAccountingConnectionRequest,
  ) {
    return this.http.post(
      `${this.url}delete-accounting-connection`,
      deleteAccountingConnectionRequest,
    );
  }
}
