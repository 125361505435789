/**
 * Trip API
 * <p>The Trip API is used to manage trips in combination with orders</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   1 = AcreFoot  2 = AuTablespoon  3 = BoardFoot  4 = Centiliter  5 = CubicCentimeter  6 = CubicDecimeter  7 = CubicFoot  8 = CubicHectometer  9 = CubicInch  10 = CubicKilometer  11 = CubicMeter  12 = CubicMicrometer  13 = CubicMile  14 = CubicMillimeter  15 = CubicYard  16 = Decaliter  17 = DecausGallon  18 = Deciliter  19 = DeciusGallon  20 = HectocubicFoot  21 = HectocubicMeter  22 = Hectoliter  23 = HectousGallon  24 = ImperialBeerBarrel  25 = ImperialGallon  26 = ImperialOunce  27 = ImperialPint  28 = KilocubicFoot  29 = KilocubicMeter  30 = KiloimperialGallon  31 = Kiloliter  32 = KilousGallon  33 = Liter  34 = MegacubicFoot  35 = MegaimperialGallon  36 = Megaliter  37 = MegausGallon  38 = MetricCup  39 = MetricTeaspoon  40 = Microliter  41 = Milliliter  42 = OilBarrel  43 = UkTablespoon  44 = UsBeerBarrel  45 = UsCustomaryCup  46 = UsGallon  47 = UsLegalCup  48 = UsOunce  49 = UsPint  50 = UsQuart  51 = UsTablespoon  52 = UsTeaspoon  53 = Nanoliter  57 = ImperialQuart
 */
export enum VolumeUnit {
  AcreFoot = 1,
  AuTablespoon = 2,
  BoardFoot = 3,
  Centiliter = 4,
  CubicCentimeter = 5,
  CubicDecimeter = 6,
  CubicFoot = 7,
  CubicHectometer = 8,
  CubicInch = 9,
  CubicKilometer = 10,
  CubicMeter = 11,
  CubicMicrometer = 12,
  CubicMile = 13,
  CubicMillimeter = 14,
  CubicYard = 15,
  Decaliter = 16,
  DecausGallon = 17,
  Deciliter = 18,
  DeciusGallon = 19,
  HectocubicFoot = 20,
  HectocubicMeter = 21,
  Hectoliter = 22,
  HectousGallon = 23,
  ImperialBeerBarrel = 24,
  ImperialGallon = 25,
  ImperialOunce = 26,
  ImperialPint = 27,
  KilocubicFoot = 28,
  KilocubicMeter = 29,
  KiloimperialGallon = 30,
  Kiloliter = 31,
  KilousGallon = 32,
  Liter = 33,
  MegacubicFoot = 34,
  MegaimperialGallon = 35,
  Megaliter = 36,
  MegausGallon = 37,
  MetricCup = 38,
  MetricTeaspoon = 39,
  Microliter = 40,
  Milliliter = 41,
  OilBarrel = 42,
  UkTablespoon = 43,
  UsBeerBarrel = 44,
  UsCustomaryCup = 45,
  UsGallon = 46,
  UsLegalCup = 47,
  UsOunce = 48,
  UsPint = 49,
  UsQuart = 50,
  UsTablespoon = 51,
  UsTeaspoon = 52,
  Nanoliter = 53,
  ImperialQuart = 57,
}
