import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDividerModule } from '@angular/material/divider';
import { NgClass } from '@angular/common';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-stepper-label',
  standalone: true,
  templateUrl: './stepper-label.component.html',
  styleUrls: ['./stepper-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatStepperModule, MatDividerModule, NgClass, IconComponent],
})
export class StepperLabelComponent {
  @Input({ required: true }) steps: string[] = [];
  @Input({ required: true }) activeIndex = 0;
}
