import {
  UrlTree,
  Params,
  UrlSegmentGroup,
  UrlSegment,
  PRIMARY_OUTLET,
} from '@angular/router';

export function containsTree(
  containeeTree: UrlTree,
  containerTree: UrlTree,
): boolean {
  const queryParamsMatch = containsQueryParams(
    containerTree.queryParams,
    containeeTree.queryParams,
  );
  const segmentGroupMatch = containsSegmentGroup(
    containerTree.root,
    containeeTree.root,
    containeeTree.root.segments,
  );

  return queryParamsMatch && segmentGroupMatch;
}

function containsQueryParams(container: Params, containee: Params): boolean {
  // TODO: This does not handle array params correctly.
  return (
    Object.keys(containee).length <= Object.keys(container).length &&
    Object.keys(containee).every((key) => containee[key] === container[key])
  );
}

/**
 * Checks if a container UrlSegmentGroup contains a containee UrlSegmentGroup.
 * It compares the paths and their segment groups recursively.
 */
function containsSegmentGroup(
  containerGroup: UrlSegmentGroup,
  containeeGroup: UrlSegmentGroup,
  containeePaths: UrlSegment[],
): boolean {
  // Case 1: If the container has more segments than the containee
  if (containerGroup.segments.length > containeePaths.length) {
    // Compare the relevant segment parts
    const containerSegments = containerGroup.segments.slice(
      0,
      containeePaths.length,
    );
    if (!equalPath(containerSegments, containeePaths)) return false; // If the paths don't match, return false

    // If the containee has child routes, it can't be contained in a group with no children
    if (containeeGroup.hasChildren()) return false;

    // This is a match if all the above conditions hold
    return true;
  }

  // Case 2: If the container has the same number of segments as the containee
  else if (containerGroup.segments.length === containeePaths.length) {
    // Check if the segment paths are equal
    if (!equalPath(containerGroup.segments, containeePaths)) return false;

    // If the containee has no children, we have a match
    if (!containeeGroup.hasChildren()) return true;

    // Recursively check child routes
    for (const childKey in containeeGroup.children) {
      // If the container doesn't have the same child route, break
      if (!containerGroup.children[childKey]) break;

      // Recursively check if the child routes also match
      if (
        containsSegmentGroup(
          containerGroup.children[childKey],
          containeeGroup.children[childKey],
          containeeGroup.children[childKey].segments,
        )
      ) {
        return true;
      }
    }

    // If none of the children match, return false
    return false;
  }

  // Case 3: If the container has fewer segments than the containee
  else {
    // Compare up to the length of the container's segments
    const currentContainerSegments = containeePaths.slice(
      0,
      containerGroup.segments.length,
    );
    const remainingContaineeSegments = containeePaths.slice(
      containerGroup.segments.length,
    );

    // Check if the paths of the segments match
    if (!equalPath(containerGroup.segments, currentContainerSegments))
      return false;

    // If the container doesn't have the primary child route, return false
    if (!containerGroup.children[PRIMARY_OUTLET]) return false;

    // Recursively check the remaining segments in the primary outlet
    return containsSegmentGroup(
      containerGroup.children[PRIMARY_OUTLET],
      containeeGroup,
      remainingContaineeSegments,
    );
  }
}

export function equalPath(
  segmentsA: UrlSegment[],
  segmentsB: UrlSegment[],
): boolean {
  // Filter out empty path segments from both arrays
  const filteredSegmentsA = segmentsA.filter((segment) => segment.path !== '');
  const filteredSegmentsB = segmentsB.filter((segment) => segment.path !== '');

  // If the lengths don't match, the paths can't be equal
  if (filteredSegmentsA.length !== filteredSegmentsB.length) return false;

  // Compare each path segment, accounting for dynamic segments (e.g., `:id`)
  return filteredSegmentsA.every(
    (segmentA, index) =>
      segmentA.path === filteredSegmentsB[index].path ||
      segmentA.path.startsWith(':') ||
      filteredSegmentsB[index].path.startsWith(':'),
  );
}
