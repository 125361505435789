import { DOCUMENT } from '@angular/common';
import { InjectionToken, inject } from '@angular/core';

export const WINDOW = new InjectionToken<Window | null>('WINDOW', {
  factory: () => inject(DOCUMENT).defaultView,
});

export const NAVIGATOR = new InjectionToken<Navigator | null>('NAVIGATOR', {
  factory: () => inject(WINDOW)?.navigator ?? null,
});
