import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cca-information-card',
  standalone: true,
  imports: [],
  templateUrl: './information-card.component.html',
  styleUrls: ['./information-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationCardComponent {
  @Input() title!: string | undefined;
  @Input() description!: string | undefined;
  @Input() vertical = false;
  @Input() imageSrc!: string;
}
