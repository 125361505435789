import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { CtrlChainIcons } from '@cca-common/core';
import { IconComponent } from '../../icon';

export enum Flavor {
  Primary,
  Secondary,
}

interface Translations {
  stop: string;
}

export interface OrderActionLike {
  label: string | undefined;
  shortDescription: string;
  iterationNumber: undefined;
  reDoActionLabel: undefined;
}

@Component({
  standalone: true,
  imports: [NgClass, IconComponent, MatRadioModule],
  selector: 'cca-action-header',
  templateUrl: './action-header.component.html',
  styleUrls: ['./action-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionHeaderComponent {
  Flavor = Flavor;
  @Input() action: OrderActionLike | undefined;
  @Input() icon: CtrlChainIcons | undefined;
  @Input() translations: Translations = {
    stop: '',
  };
  @Input() flavor: Flavor = Flavor.Primary;

  get stopNumber() {
    return 0;
  }
}
