<h3>{{ loadingOperationTitle }}</h3>
<mat-checkbox
  color="primary"
  class="text-[0.7rem]"
  [formControl]="liveLoadingForm"
  (change)="changed()"
>
  {{ liveLoadingLabel }}
</mat-checkbox>
<mat-form-field class="ml-12" subscriptSizing="dynamic">
  <mat-label>{{ hoursLabel }}</mat-label>
  <input
    [formControl]="loadingHoursForm"
    ccaInputLocale
    type="number"
    matInput
    min="1"
    step="1"
    autocomplete="off"
  />
  <span matTextSuffix>
    {{ hourSymbolLabel }}
  </span>
  <mat-error class="flex flex-wrap whitespace-nowrap">
    {{ amountErrorMsg }}
  </mat-error>
</mat-form-field>
