import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'cca-yes-no-radio',
  standalone: true,
  imports: [MatRadioModule, ReactiveFormsModule],
  templateUrl: './yes-no-radio.component.html',
  styleUrls: ['./yes-no-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoRadioComponent {
  @Input() questionLabel: string | undefined;
  @Input() button1Label: string | undefined;
  @Input() button2Label: string | undefined;
  @Input() disabled = false;
  @Input() formCtrl: FormControl = new FormControl();

  @Output() selectionChanged = new EventEmitter<boolean>();
}
