import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ChaincargoApiConfigToken,
  ChaincargoApiConfig,
} from '@cca-environment';

// a type for limiting how deep we go with the following types
type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[],
];

// joins 2 strings so Join<'a', 'b'> becomes 'a.b' also takes care of undefined:
// Join<'a', undefined> becomes 'a'
type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

// find all leaves recursively
type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
    : '';

// all Property leaves within the ChaincargoApiConfig
type NestedKeyOfChainCargoApiConfig = Leaves<ChaincargoApiConfig>;

// helper function to get the correct config string
function getProperty(obj: Record<string, string | object>, path: string) {
  const pathParts = path.split('.');
  let currentObject: Record<string, string | object> | string = obj;
  for (let i = 0, len = path.length; i < len; i++) {
    const currentPath = pathParts[i];
    if (currentPath && currentObject && typeof currentObject === 'object') {
      currentObject = currentObject[currentPath] as
        | Record<string, string | object>
        | string;
    } else {
      break;
    }
  }
  return currentObject as string;
}

export class BaseApiService {
  protected http = inject(HttpClient);
  protected config = inject(ChaincargoApiConfigToken);
  protected url: string;
  constructor(url: NestedKeyOfChainCargoApiConfig) {
    const baseUrl = `${this.config.rootUrl}${getProperty(
      this.config as unknown as Record<string, string | object>,
      url,
    )}`;
    this.url = baseUrl;
  }
}
