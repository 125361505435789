import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cca-trip-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './trip-indicator.component.html',
  styleUrl: './trip-indicator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripIndicatorComponent {
  @Input() text = '';
  @Input() numberOfStops = 0;
  @Input() stopsText = '';
}
